import styled from 'styled-components';
import media from '../../../styles/breakpoints';

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 265px;
  height: 47px;
  background: #21b0c7;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 900;
  background: linear-gradient(268.1deg, #149f6d 11.55%, #06a79d 94.19%);
  clip-path: polygon(5% 0, 95% 0, 100% 60%, 95% 100%, 5% 100%, 0 40%);
  cursor: pointer;

  & > img {
    margin-left: 8px;
    width: auto;
  }

  ${media.smallDesktop`
    font-size: 14px;
    height: 40px;
    width: 240px;
  `}

  ${media.phone`
    width: 161px;
    height: 30px;
    font-size: 9px;
  `}

  ${media.glxFoldBig`
    font-size: 12px;
    height: 36px;
    width: 200px;
  `}
`;
