import React from 'react';
import Slider from 'react-slick';

import * as S from './styled';
import logo from '../../assets/icons/logo.svg';
import map from './images/map.png';

import mockupData, { listRoadMap } from './mockup-data';

function RoadMap() {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <S.WrapContent $background="linear-gradient(180deg, #95B9EF 10%, transparent)">
      <S.WrapLogo onClick={() => window.fullpage_api?.moveTo(1)} style={{ cursor: 'pointer' }}>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.WrapHeader>
        <p className="header">THE ROADMAP</p>
        <p className="sub-header">See what we have planned for the future</p>
        <hr className="line"></hr>
      </S.WrapHeader>
      <S.WrapRoadMap>
        <S.WrapTimeLine>
          {listRoadMap.map((item, index) => (
            <S.Stage key={index}>
              <S.Circle></S.Circle>
              <p>{item.stage}</p>
            </S.Stage>
          ))}
          <S.Stage>
            <S.Circle></S.Circle>
            <p className="stage-opacity">Stage 5</p>
          </S.Stage>
        </S.WrapTimeLine>
        <S.ContentStage>
          <S.Content>
            <p className="title">2021 - COMPLETED</p>
            <p className="content">
              - Dinoverse launch <br></br> - Dinoverse Memberships <br></br> - T-Rex sale
            </p>
          </S.Content>
          <S.Content>
            <p className="title">Q1 2022</p>
            <p className="content">
              - Raptors <br></br> - Pangea Land Parcel Sale <br></br> - Pangea structures
            </p>
          </S.Content>
          <S.Content>
            <p className="title">Q2-Q3 2022</p>
            <p className="content">
              - Triceratops, Pterodactyl, Stegosaurus, Brachiosaurus..<br></br> - Eggs and Baby
              Dinos
              <br></br> Advanced Attributes
            </p>
          </S.Content>
          <S.Content>
            <p className="title">Q4 2022 - 2023</p>
            <p className="content">
              - Weapons & Accessories <br></br> - Battle Arena <br></br> - Battle Tokens
              <br></br> - Dino Reserves <br></br> - Other Mythical Creatures
            </p>
          </S.Content>
          <S.Content>
            <p className="title opacity">COMING SOON</p>
          </S.Content>
        </S.ContentStage>
      </S.WrapRoadMap>

      <S.WrapRoadMapMobile>
        <Slider {...settings}>
          {listRoadMap.map((item, index) => (
            <div>
              <S.WrapTimeLine>
                <S.Stage className="stage-1">
                  <S.Circle></S.Circle>
                  <p>{item.stage}</p>
                </S.Stage>

                <S.Stage className="stage-2" style={{ visibility: 'hidden' }}>
                  <S.Circle></S.Circle>
                  <p>Stage2</p>
                </S.Stage>

                <S.Stage className="stage-3" style={{ visibility: 'hidden' }}>
                  <S.Circle></S.Circle>
                  <p>Stage2</p>
                </S.Stage>

                <S.Stage className="stage-4" style={{ visibility: 'hidden' }}>
                  <S.Circle></S.Circle>
                  <p>Stage2</p>
                </S.Stage>

                <S.Stage className="stage-5" style={{ visibility: 'hidden' }}>
                  <S.Circle></S.Circle>
                  <p>Stage2</p>
                </S.Stage>
              </S.WrapTimeLine>
              <S.ContentStage>
                <S.Content className="content-roadmap">
                  <p className="title">{item.title}</p>
                  <p className="content">
                    {item.content.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </p>
                </S.Content>
              </S.ContentStage>
            </div>
          ))}
          <div>
            <S.WrapTimeLine>
              <S.Stage className="stage-1">
                <S.Circle></S.Circle>
                <p style={{ opacity: 0.4 }}>Stage 5</p>
              </S.Stage>

              <S.Stage className="stage-2" style={{ visibility: 'hidden' }}>
                <S.Circle></S.Circle>
                <p>Stage2</p>
              </S.Stage>

              <S.Stage className="stage-3" style={{ visibility: 'hidden' }}>
                <S.Circle></S.Circle>
                <p>Stage2</p>
              </S.Stage>

              <S.Stage className="stage-4" style={{ visibility: 'hidden' }}>
                <S.Circle></S.Circle>
                <p>Stage2</p>
              </S.Stage>

              <S.Stage className="stage-5" style={{ visibility: 'hidden' }}>
                <S.Circle></S.Circle>
                <p>Stage2</p>
              </S.Stage>
            </S.WrapTimeLine>
            <S.ContentStage>
              <S.Content className="content-roadmap">
                <p className="title" style={{ opacity: 0.4 }}>
                  COMING SOON
                </p>
              </S.Content>
            </S.ContentStage>
          </div>
        </Slider>
      </S.WrapRoadMapMobile>

      <S.ImageBottom>
        <img src={map} alt="" />
      </S.ImageBottom>
    </S.WrapContent>
  );
}

export default RoadMap;
