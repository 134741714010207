import styled from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const WrapContent = styled.div`
  top: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 100px 20px 27px 20px;

  ${media.largeDesktop`{
    padding-top: 300px;
  }`}

  // ${media.fullHDDesktop`{
  //   padding-top: 100px;
  // }`}

  // ${media.desktop`
  //   padding-top: 80px;
  // `}

  // ${media.hdDesktop`
  //   padding-top: 80px;
  // `}

  // ${media.lTablet`
  // padding-top: 250px;
  // `}

  ${media.glxFoldBig`
    padding-top: 150px;
  `}

  ${media.phone`
    padding-top: 300px;
  `}

  ${media.xsPhone`
  padding-top: 250px;
`}

  ${media.glxFold`
    padding-top: 150px;
  `}
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-size: 72px;
    line-height: 58px;
    background: -webkit-linear-gradient(#f9931a 5.46%, #d35610 96.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 48px;
    text-align: center;
  }

  .sub-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
  }

  .email {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: #425452;
    margin-bottom: 30px;
    text-align: center;

    & > span {
      color: #06a79e;
    }
  }

  .button {
    margin-top: 20px;
  }

  ${media.hdDesktop`{
    .title{
      font-size: 60px;
      margin-bottom: 16px;
    }

    .sub-title{
      font-size: 16px;
      margin-bottom: 8px;
    }

    .email{
      font-size: 14px;
      margin-bottom: 15px;
    }

    .button-back{
      width: 204px;
      height: 36px;
      font-size: 13px
    }

  }`}

  ${media.lTablet`
  .title {
    font-size: 72px;
    margin-bottom: 48px;
  }

  .sub-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .email {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .button-back{
    width: 265px;
    height: 47px;
    font-size: 16px
  }
  `}

  ${media.glxFoldBig`
    .title {
      font-size: 28px;
    line-height: 30px;
    margin-bottom: 7px;
    }

    .sub-title {
      font-size: 10px;
      line-height: 16px;
    }

    .email {
      font-size: 10px;
    margin-bottom: 8px;
    }

    .video-youtube{
      width: 100% !important;
      height: 200px !important;
    }

    .button-back{
      width: 204px;
      height: 36px;
      font-size: 13px
    }
  `}
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .buffy {
    margin-bottom: 23px;
    object-fit: none;
  }

  .policy {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #425452;
    margin-bottom: 12px;
    text-align: center;
    word-wrap: break-word;
    width: 100%;
    gap: 4px;
  }

  .copyright {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #425452;
    text-align: center;
  }

  ${media.hdDesktop`{
    .buffy{
      display:flex;
      justify-content: center;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .buffy > img{
      max-width: 65%;
    }
  }`}

  ${media.phone`{
    .policy{
      font-size: 11px;
    }
  }`}
`;
