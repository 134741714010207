import logo from '../../assets/icons/logo.svg';
import Tyranno from './images/tyranno.png';
import Gif1 from './images/raptor-and-t-rex/TREX1.gif';
import Gif2 from './images/raptor-and-t-rex/TREX2.gif';
import Gif3 from './images/raptor-and-t-rex/TREX3.gif';
import Gif4 from './images/raptor-and-t-rex/TREX4.gif';
import Gif5 from './images/raptor-and-t-rex/TREX5.gif';
import Gif6 from './images/raptor-and-t-rex/TREX6.gif';
import Gif7 from './images/raptor-and-t-rex/TREX7.gif';
import Gif8 from './images/raptor-and-t-rex/TREX8.gif';
import Gif9 from './images/raptor-and-t-rex/TREX9.gif';
import Gif10 from './images/raptor-and-t-rex/TREX10.gif';
import Stand from './images/stand.png';
import SoldOut from './images/sold-out.png';
import LeftArrow from './images/left-arrow.png';
import Background from './images/bg.png';
import ChartLeft from './images/raptor-and-t-rex/trex-s1-chart.png';
import ChartRight from './images/raptor-and-t-rex/trex-s2-chart.png';

export default {
  logo,
  Tyranno,
  Gif1,
  Gif2,
  Gif3,
  Gif4,
  Gif5,
  Gif6,
  Gif7,
  Gif8,
  Gif9,
  Gif10,
  Stand,
  SoldOut,
  LeftArrow,
  Background,
  ChartLeft,
  ChartRight,
}