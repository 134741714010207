import styled from 'styled-components';
import { Layout as La } from 'antd';
import media from '../../styles/breakpoints';

const { Content } = La;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Layout = styled(La)`
  min-height: 100vh;
  background: transparent;
  overflow-y: hidden;
`;

export const Contents = styled(Content)`
  background: #f8f8f8 !important;
  overflow-y: hidden;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  padding: 0 26px;

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 23px;
    .ant-collapse-item {
      .ant-collapse-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        color: #425452;
        font-size: 26px;
        line-height: 32px;
        padding: 0px;
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;

        ${media.glxFoldBig`
          font-size: 16px;
          width: 450px;
        `}

        ${media.phone`
          width: 300px;
        `}

        ${media.xsPhone`
          width: 250px;
        `}

        ${media.glxFold`
          width: 220px;
        `}
      }

      .ant-collapse-extra {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .other {
      margin-bottom: 8px;
    }

    .email {
      display: flex;
      gap: 16px;
    }
  }

  ${media.fullHDDesktop`
    height: 80%;
  `}

  ${media.hdDesktop`
    height: 75%;
  `}
`;

export const DFlex = styled.div`
  display: flex;

  border-radius: ${({ $borderRadius }) => $borderRadius};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  padding: ${({ $padding }) => $padding};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  flex-basis: ${({ $flexBasis }) => $flexBasis};
  align-items: ${({ $alignItems }) => $alignItems};
  gap: ${({ $gap }) => $gap};

  .follow {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 11px;
    letter-spacing: 0.155em;
    color: #707d7c;
  }

  .copyright {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
    color: #425452;
  }

  .logo-dinoverse {
    max-width: 60%;
  }

  .twitter,
  .discord {
    width: auto;
    height: 25px;
  }

  ${media.phone`{

    .social-media{
      margin-top: 90px;
    }
    .follow{
      font-size:10px;
    }
    .twitter,
    .discord {
      width: auto;
      height: 15px;
    }
  }`}

  ${media.sPhone`{
    .social-media{
      margin-top: 40px;
    }
  }`}
`;
