import styled, { css } from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  font-family: Montserrat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(180deg, #95b9ef 10%, transparent);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const WrapperMobile = styled(Wrapper)`
  display: block;
  text-align: center;
  padding: 115px 0 0;
  .slick-dots {
    bottom: -50px;
    li button:before {
      font-size: 12px;
      opacity: 1;
      color: rgba(73, 124, 118, 0.2);
    }
    li.slick-active button:before {
      color: #15a06e;
    }
  }
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const Heading = styled.h1`
  margin-top: 130px;
  font-weight: 900;
  font-style: normal;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#516462, #23312f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.hdDesktop`
      font-size: 46px;
    `}
  ${media.smallDesktop`
      font-size: 34px;
    `}
  ${media.tablet`
      marign-top: 115px;
      font-size: 24px;
      line-height: 34px;
    `}
  ${media.glxFoldBig`
      max-width: 300px;
    `}
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #222f2d;
  margin-top: 8px;
  position: relative;
`;

export const Line = styled.div`
  margin-top: 24px;
  margin-bottom: 54px;
  width: 79px;
  height: 1px;
  background-color: #4f9f14;
  ${media.tablet`
    margin-bottom: 80px;
  `}
`;

export const WrapCollection = styled.div`
  display: grid;
  max-height: calc(100vh - 293px);
  overflow: auto;
  padding-right: 20px;
  padding-bottom: 50px;
  grid-template-columns: 460px 460px 460px;
  gap: 24px 16px;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.45);
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    &:hover {
      background: transparent;
    }
  }
  ${media.desktop`
    padding-right: 10px;
    margin: 0 45px;
    grid-template-columns: 1fr 1fr 1fr;
  `}
  ${media.tablet`
    display: flex;
    grid-template-column: 1fr 1fr;
    overflow-x: scroll;
    max-width: 100%;
    max-height: fit-content;
    padding: 0 40px;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const WrapCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 45px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  img {
    margin-top: 45px;
    margin-bottom: 109px;
    height: auto;
  }
  .btn-preview {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 63%;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 59px;
    background: linear-gradient(268.1deg, #149f6d 11.55%, #06a79d 94.19%);
    clip-path: polygon(50% 0%, 93% 0%, 100% 28%, 100% 100%, 0 100%, 0 28%, 7% 0);
    ${media.hdDesktop`
      font-size: 16px;
      line-height: 50px;
      width: 60%;
    `}
    ${media.smallDesktop`
      font-size: 13px;
      line-height: 46px;
      bottom: 13px;
    `}
    ${media.tablet`
      width: 65%;
      font-size: 14px;
      line-height: 37px;
    `}
  }
  a.disabled {
    pointer-events: none;
  }
  ${media.tablet`
  max-width: 292px;
  `}
  ${media.glxFold`
    max-width: 234px
  `}
`;
