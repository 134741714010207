import React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../routes/constants';

import mockupData from './mockup-data';
import Button from '../../components/commons/Button';
import ScenePangea from '../../components/Model/ScenePangea';
import SceneMobile from '../../components/Model/SceneMobile';

import logo from '../../assets/icons/logo.svg';

import * as S from './styled';

function Pangea() {
  const history = useHistory();

  return (
    <S.Wrapper
      style={{
        backgroundImage: `url(${mockupData.backgroundCloud}), linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
      }}
    >
      <S.WrapLogo onClick={() => window.fullpage_api?.moveTo(1)} style={{ cursor: 'pointer' }}>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.WrapContent>
        <S.Content>
          <div className="title">
            <p className="text-header">PANGEA METAVERSE</p>
            <p className="sub-text-header">
              The Pangea Metaverse is a world where players are on a mission to help repopulate the
              prehistoric continent and recreate the ancient era when dinosaurs roamed Earth
            </p>
            <div className="content">
              <div>
                Dinoverse will offer players access to resources, buildings and environment that
                support all kinds of different dinosaur species. As Dinoverse Guardians, players
                will be responsible for looking after, nurturing and developing their assigned dinos
                to live and thrive in the Dinoverse!
              </div>
              <div>
                Breed, nurture, upgrade, enhance and modify your species to become a Dinoverse
                Champion and establish your own Dinoverse Guardian Clan in the metaverse!
              </div>
            </div>
            <div className="button">
              <Button label="VISIT OUR LITE PAPER" onClick={() => history.push(ROUTES.LITE)} />
              <Button label="VISIT SALE PAGE" onClick={() => history.push(ROUTES.COMING_SOON)} />
            </div>
          </div>
        </S.Content>
        <S.Model>
          <ScenePangea className="desktop" />
          <SceneMobile className="mobile" />
        </S.Model>
      </S.WrapContent>
      <S.Island>
        <img className="map-1" src={mockupData.map1} alt=""></img>
        <img className="map-2" src={mockupData.map2} alt=""></img>
        <img className="map-3" src={mockupData.map2} alt=""></img>
        <img className="map-4" src={mockupData.map2} alt=""></img>
        <img className="animation-cloud1" src={mockupData.cloud} alt=""></img>
        <img className="animation-cloud2" src={mockupData.cloud} alt=""></img>
      </S.Island>
    </S.Wrapper>
  );
}

export default Pangea;
