import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import NonAuthRoute from '../hocs/NonAuthRoute';

import WrapPage from '../containers/WrapPage';
import HomePage from '../containers/HomePage';
import About from '../containers/About';
import AboutUs from '../containers/AboutUs';
import Story from '../containers/Story';
import RoadMap from '../containers/RoadMap';
import CollectionDetail from '../containers/CollectionDetail';
import CollectionRaptor from '../containers/CollectionRaptor';
import Lite from '../containers/Lite';
import Sale from '../containers/Sale';
import Pangea from '../containers/Pangea';
import ComingSoon from '../containers/ComingSoon';
import SaleCommingSoon from '../containers/SaleComingSoon';
import Media from '../containers/Media';

import { ROUTES } from './constants';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* None auth */}
      <NonAuthRoute exact path={ROUTES.HOME} component={WrapPage} />
      <NonAuthRoute exact path={ROUTES.ABOUT} component={About} />
      <NonAuthRoute exact path={ROUTES.ABOUT_US} component={AboutUs} />
      <NonAuthRoute exact path={ROUTES.STORY} component={Story} />
      <NonAuthRoute exact path={ROUTES.ROADMAP} component={RoadMap} />
      <NonAuthRoute exact path={ROUTES.COLLECTION_DETAIL} component={CollectionDetail} />
      <NonAuthRoute exact path={ROUTES.LITE} component={Lite} />
      <NonAuthRoute exact path={ROUTES.COLLECTION_RAPTOR} component={CollectionRaptor} />
      <NonAuthRoute exact path={ROUTES.SALE} component={Sale} />
      <NonAuthRoute exact path={ROUTES.PANGEA} component={Pangea} />
      <NonAuthRoute exact path={ROUTES.COMING_SOON} component={ComingSoon} />
      <NonAuthRoute exact path={ROUTES.SALE_COMING_SOON} component={SaleCommingSoon} />
      <NonAuthRoute exact path={ROUTES.MEDIA} component={Media} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
