import logo from '../../assets/icons/logo.svg';
import Raptor from '../CollectionDetail/images/raptor.png';
import Gif1 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_1.gif';
import Gif2 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_2.gif';
import Gif3 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_3.gif';
import Gif4 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_4.gif';
import Gif5 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_5.gif';
import Gif6 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_6.gif';
import Gif7 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_7.gif';
import Gif8 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_8.gif';
import Gif9 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_9.gif';
import Gif10 from '../CollectionDetail/images/raptor-and-t-rex/RAPTOR_10.gif';
import Stand from '../CollectionDetail/images/stand.png';
import SoldOut from '../CollectionDetail/images/sold-out.png';
import LeftArrow from '../CollectionDetail/images/left-arrow.png';
import Background from '../CollectionDetail/images/bg.png';
import ChartComingSoon from '../CollectionDetail/images/raptor-and-t-rex/chart-coming-soon.png';

export default {
  logo,
  Raptor,
  Gif1,
  Gif2,
  Gif3,
  Gif4,
  Gif5,
  Gif6,
  Gif7,
  Gif8,
  Gif9,
  Gif10,
  Stand,
  SoldOut,
  LeftArrow,
  Background,
  ChartComingSoon,
};
