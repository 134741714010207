import { css } from 'styled-components';

const sizes = {
  // desktop
  largeDesktop: 2560, // x 1440
  fullHDDesktop: 1920, // x 1080
  desktop: 1600, // x 900
  mdDesktop: 1440, // x 900
  hdDesktop: 1366, // x 768
  smallDesktop: 1024, // x 768

  // mobile devices
  glxFold: 280,
  xsPhone: 375,
  sPhone: 390,
  phone: 414,
  glxFoldBig: 717, // x 512
  tablet: 768,
  lTablet: 820,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
