import styled, { css } from 'styled-components';
import { Modal as BaseModal, Tabs } from 'antd';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  padding: 0 243px;
  font-family: Montserrat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: transparent;
  &:hover {
    overflow-y: auto;
  }
  &::-webkit-scrollbar {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    &:hover {
      background-color: lightgray;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    &:hover {
      background: transparent;
    }
  }

  ${media.largeDesktop`
    padding: 0 0 50px;
  `}
  ${media.desktop`
    padding: 0;
  `}
  ${media.phone`
    overflow-y: auto;
    padding: 0 16px;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const WrapDetail = styled.div`
  margin-top: 140px;
  margin-bottom: 57px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px 130px;
  .btn-back {
    margin-bottom: 36px;
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    color: #716e82;
    background-color: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
      width: auto;
      height: 15px;
    }
  }
  .thumbnail-wrap {
    display: flex;
    flex-direction: column;
  }
  .thumbnail-img {
    min-width: 554px;
    ${media.hdDesktop`
      min-width: 400px;
      width: 400px;
    `}
    ${media.lTablet`
      min-width: 250px;
      width: 250px;
    `}
    ${media.phone`
      min-width: 100%;
      width: 100%;
      margin-top: 26px;
    `}
  }
  ${media.largeDesktop`
    gap: 0 30px;
  `}
  ${media.desktop`
    margin-top: 80px;
    // margin-bottom: 20px;
  `}
  ${media.phone`
    flex-direction: column;
    margin-top: 75px;
    margin-bottom: 43px;
    align-items: stretch;
  `}
`;

export const Detail = styled.div`
  width: 734px;
  font-family: Montserrat;
  padding: 32px 42px 42px;
  background: rgba(255, 255, 255, 0.54);
  display: flex;
  flex-direction: column;

  .button-visit {
    display: flex;
    margin-top: 30px;
  }

  ${media.hdDesktop`
    padding: 0px 36px;
    width: 500px;
  `}
  ${media.tablet`
    padding: 20px 36px;
    width: 400px;
  `}
  ${media.phone`
    width: 100%;
    padding: 16px;

    .button-visit {
      display: flex;
      justify-content: center;
  
      .button-raptor {
        font-size: 10px;
        width: 165px;
      }
    }
  `}

  ${media.phone`

    .button-visit {
      .button-raptor {
        font-size: 10px;
        width: 145px;
      }
    }
  `}
`;

export const DetailHeading = styled.h1`
  margin-bottom: 15px;
  font-weight: 900;
  font-size: 36px;
  line-height: 58px;
  background: -webkit-linear-gradient(#516462, #23312f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.hdDesktop`
      font-size: 30px;
      line-height: 50px;
      margin-bottom: 10px;
    `}
  ${media.phone`
      font-size: 28px;
      line-height: 28px;
    `}
`;

export const DetailLabel = styled.h6`
  margin-bottom: 5px;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  color: #3d4e4c;
  ${media.phone`
      font-size: 14px;
    `}
`;

export const DetailContent = styled.p`
  font-family: Montserrat;
  // margin-bottom: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  strong {
    font-weight: 600;
  }
  .strong-text {
    color: #15a06e;
  }
  ul {
    padding-left: 15px;
    li {
      position: relative;
      ::before {
        content: '';
        position: absolute;
        top: 12px;
        left: -8px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background: #3d4e4c;
      }
    }
  }

  ${media.hdDesktop`
      font-size: 12px;
    `}
  ${media.phone`
      font-size: 14px;

      .button-visit > {

      }
    `}
`;

export const WrapTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Tab = styled(Tabs)`
  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav {
    margin-bottom: 8px;
    ::before {
      display: none;
    }
    // ${media.hdDesktop`
    //   margin-bottom: 0px;
    // `}
    ${media.phone`
      margin-bottom: 16px;
    `}
  }

  .ant-tabs-tab {
    width: 363px;
    height: 33px;
    text-align: center;
    background-color: #2a3635;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
    clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
    ${media.hdDesktop`
      width: 300px;
    `}
    ${media.phone`
      width: 178px;
      margin: 0;
      -webkit-clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    `}
  }

  .ant-tabs-tab-active {
    background-color: #15a06e;
  }

  .ant-tabs-tab-btn {
    font-family: Montserrat;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    ${media.hdDesktop`
      font-size: 15px;
    `}
  }

  .content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #2a3635;
  }
`;

export const Preview = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 75px;
  display: grid;
  grid-template-columns: 240px 240px 240px 240px 240px;
  gap: 0 8px;
  overflow: hidden;
  // ${media.smallDesktop`
  //   max-width: 992px;
  // `}
  ${media.hdDesktop`
    grid-template-columns: 240px 240px 240px 240px;
  `}
  ${media.lTablet`
    grid-template-columns: 240px 240px 240px;
  `}
  ${media.phone`
    grid-template-columns: 170px 170px;
    margin: 0 auto;
    gap: 0 10px;
  `}
`;

export const PreviewItem = styled.div`
  position: relative;
  width: fit-content;

  .preview-stand {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    ${media.phone`
      bottom: 47px;
      width: 114px;
    `}
  }
  .preview-name {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0px;
    ${media.phone`
      width: 170px;
      // bottom: 45px;
    `}
  }
`;

export const PreviewItemThumbnail = styled.div`
  width: fit-content;
  height: 240px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  .sold-out {
    position: absolute;
    top: 50px;
    right: 3px;
    ${media.phone`
      width: 55px;
      top: 38px;
    `}
  }
  .preview-dino {
    width: 240px;
    min-width: 240px;
    // height: 120px;
    // min-height: 120px;
    object-fit: cover;
    ${media.phone`
      width: 170px;
      min-width: 170px;
    `}
  }
  ${media.phone`
    margin-bottom: 23px;
    height: 170px;
  `}
`;

export const WrapChart = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  padding: 70px 0;
  gap: 0 60px;
  .vertical-line {
    height: 210px;
    width: 1px;
    background-color: #c1c1c1;
    ${media.phone`
      display: none;
      `}
  }
  ${media.tablet`
      gap: 0 20px;
    `}
  ${media.phone`
      flex-direction: column;
      gap: 40px 0;
    `}
`;

export const ChartBox = styled.div`
  width: 292px;
  height: 292px;
  position: relative;
  .chart__text {
    width: 185px;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    &-title {
      margin-bottom: 8px;
      font-size: 34px;
      font-weight: 900;
      line-height: 36px;
      text-transform: uppercase;
      background: -webkit-linear-gradient(#516462, #23312f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ${media.smallDesktop`
        font-size: 30px;
        line-height: 30px;
      `}
      ${media.tablet`
        font-size: 27px;
        line-height: 27px;
      `}
    }
    &-subtitle {
      font-size: 14px;
      line-height: 24px;
      color: #222f2d;
      ${media.smallDesktop`
        font-size: 13px;
        line-height: 22px;
      `}
      ${media.smallDesktop`
        font-size: 12px;
        line-height: 20px;
      `}
    }
  }
  ${media.smallDesktop`
    width: 240px;
    height: 240px;
  `}
  ${media.tablet`
    width: 220px;
    height: 220px;
    `}
`;

export const WrapChartItem = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  width: fit-content;
  gap: 0 40px;
  ${media.smallDesktop`
    gap: 0 30px;
  `}
  ${media.tablet`
    gap: 0 10px;
  `}
  ${media.phone`
    flex-direction: row;
  `}
`;

export const ChartLegend = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px 0;
  ${media.smallDesktop`
    gap: 13px;
  `}
  ${media.phone`
    min-width: 128px;
  `}
`;

export const ChartLegendItem = styled.li`
  position: relative;
  padding-left: 35px;
  .legend-label {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-transform: uppercase;
    ${media.smallDesktop`
      font-size: 11px;
      line-height: 16px;
    `}
    ${media.tablet`
      font-size: 10px;
      line-height: 15px;
    `}
  }
  .legend-percent {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    ${media.smallDesktop`
      font-size: 20px;
      line-height: 24px;
    `}
    ${media.smallDesktop`
      font-size: 18px;
      line-height: 22px;
    `}
  }
  &:before {
    content: '';
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: ${({ background }) => background || 'gray'};
    position: absolute;
    top: 50%;
    transform: translateY(-65%);
    left: 0;
    ${media.smallDesktop`
      width: 16px;
      height: 16px;
    `}
    ${media.tablet`
      width: 13px;
      height: 13px;
    `}
  }
  ${media.smallDesktop`
    padding-left: 30px;
  `}
  ${media.tablet`
    padding-left: 25px;
  `}
`;

export const ModalYoutube = styled(BaseModal)`
  width: 1010px !important;
  .ant-modal-content {
    width: 1010px;
    height: fit-content;
  }
  .ant-modal-body {
    padding: 0;
    height: 568px;
  }
  }
  .video-frame {
    width: 1010px;
    height: 568px;
  }
`;
