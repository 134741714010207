/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { REACT_FULLPAGE_KEY, REACT_FULLPAGE_RESPONSIVE_WIDTH } from '../../constants/configs';

const Fullpage = (props) => (
  <>
    <ReactFullpage
      licenseKey={REACT_FULLPAGE_KEY}
      scrollingSpeed={500}
      navigation
      {...props}
      render={({ state, fullpageApi }) => (
        <ReactFullpage.Wrapper>{props.children}</ReactFullpage.Wrapper>
      )}
    />
  </>
);

export default Fullpage;
