import styled from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const WrapContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  ${media.lTablet`
    flex-direction: column;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  padding: 165px 0 0 236px;

  .title {
    display: flex;
    flex-direction: column;
    gap: 32px;

    font-family: Montserrat;
    font-style: normal;
    color: #222f2d;
  }

  .text-header {
    font-weight: 900;
    font-size: 96px;
    line-height: 80px;
    background: -webkit-linear-gradient(#f9931a 5.46%, #d35610 96.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 30px;
  }

  .sub-text-header {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 50px;
    padding-right: 60px;
  }

  .content {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .button {
    z-index: 4;
    display: flex;
    gap: 32px;

    ${media.smallDesktop`
      flex-direction: column;
      gap: 4px;
    `}

    ${media.lTablet`
      flex-direction: row;
      gap: 32px;
    `}

    ${media.phone`
      flex-direction: column;
      gap: 8px;
    `}
  }

  ${media.fullHDDesktop`
    .text-header {
      margin-bottom: 10px;
    }

    .sub-text-header {
      padding: 0px;
      margin-bottom: 0px;
    }

    .content {
      margin-bottom: 0px;
    }
  `}

  ${media.desktop`
    padding: 90px 0 0 90px;
  `}

  ${media.hdDesktop`
    padding: 90px 0 0 50px;

    .title {
      gap: 16px;
    }

    .text-header {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 0px;
    }

    .sub-text-header {
      line-height: 24px;
      margin-bottom: 0px;
    }

    .content {
      line-height: 24px;
    }
  `}

  ${media.smallDesktop`
    padding: 80px 0 0 40px;

    .text-header {
      font-size: 54px;
      line-height: 60px;
      margin-bottom: 0px;
    }

    .sub-text-header {
      font-size: 18px;
      line-height: 24px;
    }

    .content {
      line-height: 20px;
    }
  `}

  ${media.lTablet`
    padding: 100px 50px 0 50px;

    .title {
      align-items: center;
      text-align: center;
    }

    .text-header {
      font-size: 50px;
      line-height: 62px;
    }

    .sub-text-header {
      font-size: 18px;
      line-height: 22px;
    }

    .content {
      line-height: 18px;
      margin-bottom: 20px;
    }
  `}

  ${media.tablet`
    .text-header {
      font-size: 48px;
      line-height: 48px;
    }

    .sub-text-header {
      font-size: 18px;
      line-height: 20px;
    }

    .content {
      font-size: 16px;
      line-height: 20px;
    }
  `}

  ${media.glxFoldBig`
    padding: 70px 20px 0px 20px;

    .text-header {
      font-size: 36px;
      line-height: 36px;
    }

    .sub-text-header {
      font-size: 16px;
      line-height: 20px;
    }

    .content {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  `}

  ${media.phone`
    .text-header {
      font-size: 34px;
      line-height: 34px;
    }

    .sub-text-header {
      font-size: 14px;
      line-height: 20px;
    }

    .content {
      font-size: 12px;
      line-height: 20px;
    }
  `}

  ${media.xsPhone`
    .text-header {
      font-size: 32px;
      line-height: 32px;
    }

    .sub-text-header {
      font-size: 12px;
      line-height: 20px;
    }

    .content {
      font-size: 10px;
      line-height: 18px;
    }
  `}

  ${media.glxFold`
    padding: 100px 15px 0px 15px;
  `}
`;

export const Model = styled.div`
  display: flex;
  flex-basis: 60%;
  width: 100%;
  z-index: 1;

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  ${media.lTablet`
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  `}

  ${media.glxFold`
    .mobile {
      display: none;
    }
  `}
`;

export const Island = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40%;

  .map-1 {
    opacity: 0.8;
    position: absolute;
    width: 15%;
    left: 5%;
    animation: map-1 5s linear infinite;
    @keyframes map-1 {
      0%,
      100% {
        top: 100px;
      }
      50% {
        top: 230px;
      }
    }
  }

  .map-2 {
    position: absolute;
    opacity: 0.3;
    width: 10%;
    left: 20%;
    animation: map-2 5s linear infinite;
    @keyframes map-2 {
      0%,
      100% {
        top: 20px;
      }
      50% {
        top: 40px;
      }
    }
  }

  .map-3 {
    position: absolute;
    opacity: 0.1;
    width: 5%;
    left: 35%;
    animation: map-3 5s linear infinite;
    @keyframes map-3 {
      0%,
      100% {
        top: 0px;
      }
      50% {
        top: 30px;
      }
    }
  }

  .map-4 {
    position: absolute;
    left: 40%;
    width: 15%;
    animation: map-4 6s linear infinite;
    @keyframes map-4 {
      0%,
      100% {
        top: 80px;
      }
      50% {
        top: 220px;
      }
    }
  }

  .animation-cloud1 {
    position: absolute;
    left: 0px;
    animation: run1 16s linear infinite;
    z-index: 3;

    @keyframes run1 {
      0% {
        left: 0;
      }
      50% {
        left: calc(100% - 100px);
      }
      100% {
        left: 0;
      }
    }
  }

  .animation-cloud2 {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    position: absolute;
    right: 0px;
    animation: run2 12s linear infinite;
    z-index: 3;

    @keyframes run2 {
      0% {
        right: 0;
      }
      50% {
        right: calc(100% - 100px);
      }
      100% {
        right: 0;
      }
    }
  }

  ${media.fullHDDesktop`
    height: 30%;

    .map-1 {
      left: 0px;
      animation: map-1 5s linear infinite;
      @keyframes map-1 {
        0%,
        100% {
          top: 20px;
        }
        50% {
          top: 100px;
        }
      }
    }

    .map-4 {
      right: -100px;
      animation: map-4 6s linear infinite;
      @keyframes map-4 {
        0%,
        100% {
          top: 30px;
        }
        50% {
          top: 140px;
        }
      }
    }
  `}

  ${media.hdDesktop`
    .map-1 {
      animation: map-1 5s linear infinite;
      @keyframes map-1 {
        0%,
        100% {
          top: 0px;
        }
        50% {
          top: 90px;
        }
      }
    }

    .map-4 {
      animation: map-4 6s linear infinite;
      @keyframes map-4 {
        0%,
        100% {
          top: 0px;
        }
        50% {
          top: 100px;
        }
      }
    }
  `}

  ${media.smallDesktop`
    .map-1 {
      animation: map-1 5s linear infinite;
      @keyframes map-1 {
        0%,
        100% {
          top: 30px;
        }
        50% {
          top: 140px;
        }
      }
    }

    .map-4 {
      animation: map-4 6s linear infinite;
      @keyframes map-4 {
        0%,
        100% {
          top: 0px;
        }
        50% {
          top: 90px;
        }
      }
    `}

  ${media.lTablet`
  .map-1 {
    display: none;
  }

  .map-3 {
    position: absolute;
    width: 50%;
    right: 15%;
    animation: map-3 5s linear infinite;
    @keyframes map-3 {
      0%,
      100% {
        top: 20px;
      }
      50% {
        top: 40px;
      }
    }
  }

  .map-4 {
    display: none;
    }
  `}
`;
