/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Drawer, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { section, tab } from '../../store/authSlice';

import { ROUTES } from '../../routes/constants';

import { MenuItem } from '../commons/Typo';

import logo from '../../assets/icons/big-logo.svg';
import twitter from '../../assets/icons/iconTwitter.svg';
import discord from '../../assets/icons/iconDiscord.svg';
import menu from '../../assets/icons/iconGroup.svg';
import nav from '../../assets/image/png/nav.png';
import nav_active from '../../assets/image/png/nav-active.png';

import * as S from './styled';

const { Panel } = Collapse;

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, set_visible] = useState(false);
  const [panel, set_panel] = useState([]);

  const arrMenu = [
    {
      title: 'HOME',
      link: ROUTES.HOME,
      collapse: false,
      section: 1,
    },
    {
      title: 'ABOUT US',
      collapse: true,
      content: [
        {
          title: 'PROJECT OVERVIEW',
          link: '/',
          section: 2,
          tab: '1',
        },
        {
          title: 'THE TEAM',
          link: '/',
          section: 2,
          tab: '2',
        },
        {
          title: 'ROADMAP',
          link: '/',
          section: 3,
        },
      ],
    },
    {
      title: 'LITE PAPER',
      link: ROUTES.LITE,
      collapse: false,
    },
    {
      title: 'THE STORY SO FAR',
      link: ROUTES.STORY,
      collapse: false,
    },
    {
      title: 'DINO COLLECTIONS',
      collapse: true,
      content: [
        {
          title: 'T-REX',
          link: ROUTES.COLLECTION_DETAIL,
        },
        {
          title: 'RAPTORS',
          link: ROUTES.COLLECTION_RAPTOR,
        },
      ],
    },
    {
      title: 'PANGEA METAVERSE',
      collapse: true,
      content: [
        {
          title: 'LAND SALE',
          link: ROUTES.COMING_SOON,
        },
        {
          title: 'COLLABORATIONS',
          link: ROUTES.COMING_SOON,
        },
      ],
    },
    {
      title: 'CONTACT US',
      collapse: true,
      content: [
        {
          title: 'EMAIL',
          link: 'SOCIAL@DINOVERSE.IO',
        },
      ],
    },
  ];

  const arrSection = [
    {
      id: '1',
      section: 2,
    },
    {
      id: '4',
      section: 4,
    },
    {
      id: '5',
      section: 5,
    },
  ];

  const onClose = () => {
    set_visible(false);
  };

  const onOpen = () => {
    set_visible(true);
  };

  const callback = (key) => {
    set_panel(key);

    const arr = arrSection.filter((item) => item.id === key);
    dispatch(section(arr[0]?.section));
    history.push(ROUTES.HOME);
  };

  if (visible) {
    window.fullpage_api?.setAllowScrolling(false);
  } else {
    window.fullpage_api?.setAllowScrolling(true);
  }

  return (
    <S.Wrapper>
      <S.Layout>
        <S.Layout>
          <S.Contents>{children}</S.Contents>
          <div
            className="open-menu"
            onClick={onOpen}
            style={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
              zIndex: 500,
            }}
          >
            <img src={menu} alt="" />
          </div>
        </S.Layout>
        <Drawer
          width={window.innerWidth >= 768 ? 500 : window.innerWidth}
          closable
          visible={visible}
          onClose={onClose}
        >
          <S.DFlex
            $alignItems="center"
            $justifyContent="flex-start"
            $marginBottom={window.innerWidth >= 768 ? '100px' : '50px'}
            $padding="0 26px"
          >
            <img className="logo-dinoverse" src={logo} alt="" />
          </S.DFlex>
          <S.Options>
            <S.DFlex $flexDirection="column" $alignItems="flex-start" padding="0 16px">
              <Collapse accordion ghost onChange={callback}>
                {arrMenu.map((item, index) =>
                  !item.collapse ? (
                    <MenuItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(item.link);
                        window.fullpage_api?.moveTo(item?.section);
                        set_visible(false);
                      }}
                      key={index}
                      $fontSize={window.innerWidth >= 768 ? '26px' : '16px'}
                    >
                      {item.title}
                    </MenuItem>
                  ) : (
                    <Panel
                      showArrow={false}
                      key={index}
                      header={item.title}
                      extra={
                        !panel?.includes(`${index}`) ? (
                          <PlusOutlined style={{ color: '#06A79E' }} />
                        ) : (
                          <MinusOutlined style={{ color: '#06A79E' }} />
                        )
                      }
                    >
                      {item.content?.map((item, index) =>
                        item.title !== 'EMAIL' ? (
                          <div className="other">
                            <MenuItem
                              $fontSize={window.innerWidth >= 768 ? '16px' : '12px'}
                              $lineHeight="20px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (item.section) {
                                  window.fullpage_api?.moveTo(item.section);
                                  set_visible(false);
                                  dispatch(tab(item.tab));
                                } else if (item.link) {
                                  history.push(item.link);
                                  set_visible(false);
                                }
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          </div>
                        ) : (
                          <div className="email">
                            <MenuItem $fontSize="16px" $lineHeight="20px">
                              {item.title}
                            </MenuItem>
                            <a
                              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=SOCIAL@DINOVERSE.IO"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <MenuItem $fontSize="16px" $lineHeight="20px" $color="#06A79E">
                                {item.link}
                              </MenuItem>
                            </a>
                          </div>
                        )
                      )}
                    </Panel>
                  )
                )}
              </Collapse>
            </S.DFlex>
            <div className="under">
              <S.DFlex
                $flexDirection="column"
                $justifyContent="flex-end"
                $alignItems="center"
                $gap="30px"
              >
                <S.DFlex $flexDirection="column" $gap="20px" className="social-media">
                  <div className="follow">FOLLOW US</div>
                  <S.DFlex className="logo" $gap="16px" $alignItems="center">
                    <a
                      href="https://twitter.com/dinoverseNFT"
                      target="_blank"
                      rel="noreferrer"
                      style={{ width: '100%' }}
                    >
                      <img className="twitter" src={twitter} alt="" />
                    </a>
                    <MenuItem style={{ fontSize: '10px' }}>|</MenuItem>
                    <a
                      href="https://discord.me/dinoversenft"
                      target="_blank"
                      rel="noreferrer"
                      style={{ width: '100%' }}
                    >
                      <img className="discord" src={discord} alt="" />
                    </a>
                  </S.DFlex>
                </S.DFlex>
                <S.DFlex>
                  <div className="copyright">Copyright © 2022 Dinoverse. All Rights Reserved</div>
                </S.DFlex>
              </S.DFlex>
            </div>
          </S.Options>
        </Drawer>
      </S.Layout>
    </S.Wrapper>
  );
}
