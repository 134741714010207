/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import mockupData from './mockup-data';
import SceneHomePage from '../../components/Model/SceneHomePage';
import Button from '../../components/commons/Button';
import SceneMobile from '../../components/Model/SceneMobile';

import { ROUTES } from '../../routes/constants';

import announcement from './images/announcement.svg';
import logo from '../../assets/icons/logo.svg';
import dot from './images/dot.png';

import * as S from './styled';

function HomePage() {
  const history = useHistory();
  const section = useSelector((state) => state.authReducer?.section);

  useEffect(() => {
    window.fullpage_api?.moveTo(section);
  }, [section]);

  const arrAnnouncement = [
    {
      id: 1,
      text: 'The Raptor Sale will start 0:00 UTC 6 March 2022.',
      link: 'Visit sale page now',
      dest: ROUTES.COLLECTION_RAPTOR,
    },
    {
      id: 2,
      text: 'Join the white list for early access to the Pangea Land Sale.',
      link: 'Read more',
      dest: 'https://discord.me/dinoversenft',
    },
    {
      id: 3,
      text: 'Read the Dinoverse light paper and join the community on Discord',
      link: 'Read more',
      dest: ['https://discord.me/dinoversenft', ROUTES.LITE],
    },
  ];

  return (
    <S.Wrapper
      style={{
        backgroundImage: `url(${mockupData.backgroundCloud}), linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
      }}
    >
      <S.WrapContent>
        <S.Header>
          <div className="big-logo">
            <img src={mockupData.logo} alt=""></img>
          </div>

          <div className="small-logo">
            <img src={logo} alt=""></img>
          </div>

          <div className="title">
            <p className="text-header">
              Bringing dinos back to <span className="pangea">Pangea</span>
            </p>
          </div>
          <div className="button">
            <a href="https://discord.me/dinoversenft" target="_blank" rel="noreferrer">
              <Button label="JOIN THE DINOVERSE" hasIcon />
            </a>
          </div>
          <S.Announcement>
            <div className="announcement">
              <img src={announcement} alt="" />
              ANNOUNCEMENT
            </div>
            <div className="link">
              {arrAnnouncement.map((item, index) =>
                item.id === 1 ? (
                  <div key={index}>
                    <img src={dot} alt="" />
                    {item.text} <span onClick={() => history.push(item.dest)}>{item.link}</span>
                  </div>
                ) : item.id === 2 ? (
                  <div key={index}>
                    <img src={dot} alt="" />
                    {item.text} <span onClick={() => window.open(item.dest)}>{item.link}</span>
                  </div>
                ) : (
                  <div key={index}>
                    <img src={dot} alt="" />
                    {item.text}{' '}
                    <span
                      onClick={() => {
                        window.open(item.dest[0]); // discord
                        history.push(item.dest[1]); // lite
                      }}
                    >
                      {item.link}
                    </span>
                  </div>
                )
              )}
            </div>
          </S.Announcement>
          <S.FollowUs>
            <p>FOLLOW US</p>
            <S.IconFollowUs>
              <a href="https://twitter.com/dinoverseNFT" target="_blank" rel="noreferrer">
                <img className="icon-twitter" src={mockupData.twitter} alt=""></img>
              </a>
              <a href="https://discord.me/dinoversenft" target="_blank" rel="noreferrer">
                <img className="icon-discord" src={mockupData.discord} alt=""></img>
              </a>
            </S.IconFollowUs>
          </S.FollowUs>
        </S.Header>

        <S.Model>
          <img className="map-left" src={mockupData.map1} alt=""></img>
          <img className="map-right" src={mockupData.map2} alt=""></img>
          <img className="animation-cloud" src={mockupData.cloud} alt=""></img>
        </S.Model>
      </S.WrapContent>
      <S.WrapModel>
        <SceneHomePage className="desktop" />
        <SceneMobile className="mobile" />
      </S.WrapModel>
    </S.Wrapper>
  );
}

export default HomePage;
