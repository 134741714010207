import styled from 'styled-components';
import { Tabs } from 'antd';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  padding: 50px;
  width: 100%;
  height: 100vh;
  text-align: center;
  background: linear-gradient(180deg, #95b9ef 10%, transparent);
  overflow: scroll;

  ${media.lTablet`
    overflow: hidden;
  `}

  ${media.glxFoldBig`
    overflow: scroll;
  `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const WrapTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;

  ${media.phone`
    margin-top: 32px;

  `}
`;

export const Tab = styled(Tabs)`
  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav {
    margin-bottom: 8px;
    ::before {
      display: none;
    }
    ${media.phone`
      margin-bottom: 16px;
    `}
  }

  .ant-tabs-tab {
    width: 363px;
    height: 33px;
    text-align: center;
    background-color: #2a3635;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
    clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
    ${media.hdDesktop`
      width: 300px;
    `}
    ${media.phone`
      width: 178px;
      margin: 0;
      -webkit-clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    `}

    ${media.glxFold`
      width: 115px;
  `}
  }

  .ant-tabs-tab-active {
    background-color: #15a06e;
  }

  .ant-tabs-tab-btn {
    font-family: Montserrat;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    ${media.hdDesktop`
      font-size: 15px;
    `}

    ${media.phone`
      font-size: 12px;
    `}

    ${media.glxFold`
      font-size: 6px;
    `}
  }
`;

export const WrapHeader = styled.div`
  .header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    margin-top: 50px;

    background: linear-gradient(180deg, #516462 0%, #23312f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .sub-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #222f2d;
    padding-top: 8px;
  }

  .line {
    width: 79px;
    border: 1px solid #4f9f14;
    margin: 0 auto;
    margin-top: 24px;
  }

  ${media.hdDesktop`
  .header {
      font-size: 30px;
    }
    `}

  ${media.smallDesktop`
    .header {
      font-size: 30px;
    }
  `}

  ${media.phone` 

    .header{
      font-size: 24px;
    }

    .sub-header{
      font-size: 12px;
      padding-top: 0px;
    }
  `}

  ${media.sPhone` 

  .header{
    font-size: 30px;
  }

  .sub-header{
    font-size: 11px;
  }
`}

${media.glxFold` 

.header{
  font-size: 20px;
  line-height: 30px
}

.sub-header{
  font-size: 8px;
}
`}
`;

export const WrapContent = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  .wrap-content {
    display: flex;
    justify-content: flex-start;
    background: white;
    width: 700px;
    height: 250px;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    gap: 20px;

    .thumbnails {
      flex-basis: 40%;
    }

    .thumnails > img {
      width: 254px;
      height: 169px;
      object-fit: contain;
      border-radius: 16px;
    }

    .content {
      flex-basis: 60%;
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 8px;

      .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 34px;
        text-transform: capitalize;
        color: #222f2d;
      }

      .description {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        text-transform: capitalize;
        color: #222f2d;
      }

      .link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #969696;
      }

      .readmore {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: #15a06e;
      }
    }
  }

  ${media.phone`

  margin-top: 10px;

  .wrap-content{
    width: 349px;
    height: 399px;
    flex-direction: column;

    .content{
      margin-left: 0px;
      text-align: center
    }

    .thumnails > img {
      width: 200px;
      height: 170px;
    }
  }
  `}

  ${media.glxFold`
    .wrap-content{
      width: 242px;
    }
  
  `}
`;
