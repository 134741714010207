import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './styled';
import SuperPangeaImg from './images/supercontinent-of-pangea.png';
import PangeaRegionImg from './images/pangea-region.png';
import ZoneDistrictImg from './images/zone-district.png';
import DinoverseImg1 from './images/dinoverse1.png';
import DinoverseImg2 from './images/dinoverse2.png';

function Lite() {
  const inputRef = useRef(null);
  const contentRef = useRef(null);
  const [visibleNav, setVisibleNav] = useState(false);
  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      // if (inputRef.current.value !== '') {
      //   // contentRef.current.innerHTML = contentRef.current.innerHTML.replaceAll('<mark>', '');
      //   // contentRef.current.innerHTML = contentRef.current.innerHTML.replaceAll('</mark>', '');
      //   const regExp = new RegExp(inputRef.current.value, 'g');
      //   console.log(regExp);
      //   contentRef.current.innerHTML = contentRef.current.innerHTML.replace(
      //     regExp,
      //     `<mark>${inputRef.current.value}</mark>`
      //   );
      // }
    }
  };

  return (
    <S.Wrapper>
      <S.TopNav>
        <div>
          <a className="topnav-logo">
            <S.TopNavToggle onClick={() => setVisibleNav(true)}>
              &#9776;
            </S.TopNavToggle>
            Dinoverse Pangea Metaverse Lite Pager
          </a>
        </div>
        <div>
          <Link to="/" className="topnav-logo">
            Website
          </Link>
        </div>
      </S.TopNav>
      <S.MainWrapper>
        <S.SideBar display={visibleNav}>
          <S.CloseSideBarBtn
            style={{ display: `${visibleNav ? 'block' : 'none'}` }}
            onClick={() => setVisibleNav(false)}
          >
            &#9587;
          </S.CloseSideBarBtn>
          <ul>
            <li>
              <a href="#overview">
                <p>Overview of the Dinoverse Metaverse</p>
              </a>
              <ul>
                <li>
                  <a href="#overview-s1">Backstory</a>
                </li>
                <li>
                  <a href="#overview-s2">The Dinoverse world of Pangea</a>
                  <ul>
                    <li>
                      <a href="#overview-s21">The Supercontinent of Pangea</a>
                    </li>
                    <li>
                      <a href="#overview-s22">Pangea Regions</a>
                    </li>
                    <li>
                      <a href="#overview-s23">Zones and Districts</a>
                    </li>
                    <li>
                      <a href="#overview-s24">Plots and Land parcels</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#overview-s3">Introducing the Pangea Ecosystem</a>
                  <ul>
                    <li>
                      <a href="#overview-s31">Land Management</a>
                    </li>
                    <li>
                      <a href="#overview-s32">Building Management</a>
                    </li>
                    <li>
                      <a href="#overview-s33">Dino Management</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <a href="#gamestyle">
                <p>Gamestyle</p>
              </a>
              <ul>
                <li>
                  <a href="#gamestyle-section1">Play style</a>
                </li>
                <li>
                  <a href="#gamestyle-section2">Player roles</a>
                </li>
                <li>
                  <a href="#gamestyle-section3">Player ecosystem</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#technology">
                <p>Technology direction and Roadmap</p>
              </a>
              <ul>
                <li>
                  <a href="#technology-section1">
                    Why build a Cardano NFT metaverse
                  </a>
                </li>
                <li>
                  <a href="#technology-section2">
                    Dinoverse NFTs and meta-game mechanics
                  </a>
                </li>
                <li>
                  <a href="#technology-section3">2022 Roadmap</a>
                </li>
              </ul>
            </li>
          </ul>
        </S.SideBar>
        <S.ContentWrapper>
          <S.Content ref={contentRef}>
            {/* Overview */}
            <h2
              className="content-heading"
              style={{ marginTop: 0 }}
              id="overview"
            >
              Overview of the Dinoverse Metaverse
            </h2>
            <img src={DinoverseImg1} alt="" />
            <br />
            <img src={DinoverseImg2} alt="" />
            <h3 id="overview-s1" className="content-label">
              Backstory
            </h3>
            <p className="content-text">
              Dinoverse is a 3D interactive Dinosaur model NFT project on the
              Cardano blockchain. The project was launched in September 2021,
              and has been steadily building and releasing high quality original
              3D Dinosaur models as NFTs, starting with the T-Rex collection.
            </p>
            <p className="content-text">
              The Dinoverse team has responded to our community requests by
              launching the Pangea Metaverse. This Pangea metaverse world will
              enable our community to take on the role of Dinoverse Guardians
              and Champions, whose mission is to help repopulate the meta world
              of Dinoverse with dinosaurs and restore the supercontinent of
              Pangea.
            </p>
            <p className="content-text">
              The Dinoverse will offer players access to resources, buildings
              and environments that support all kinds of different dinosaur
              species.
            </p>
            <p className="content-text">
              As a Dinoverse Guardian, you will be given the responsibility of
              looking after, nurturing and developing your assigned dinos to
              live and thrive in the Dinoverse!
            </p>
            <p className="content-text">
              Breed, nurture, upgrade, enhance and modify your species to become
              a Dinoverse Champion and establish your own Dinoverse Guardian
              Clan in the metaverse!
            </p>
            <h3 id="overview-s2" className="content-label">
              The Dinoverse world of Pangea
            </h3>
            <p className="content-text">
              The Dinoverse virtual world is based on the historic
              supercontinent of Pangea. The 3D world of Pangea is a persistent
              virtual space that connects the Dinoverse together.
            </p>
            <p className="content-text">
              Land in the Dinoverse is divided into a hierarchy:
            </p>
            <ul className="content-list content-list--decimal">
              <li>
                <p>The Supercontinent of Pangea</p>
              </li>
              <li>
                <p>Regions of Pangea</p>
              </li>
              <li>
                <p>Zones and Districts</p>
              </li>
              <li>
                <p>Plots and land parcels</p>
              </li>
            </ul>
            <h3 id="overview-s21" className="content-label content-label-lv2">
              The Supercontinent of Pangea
            </h3>
            <p className="content-text">
              Pangea represents the highest level of the Dinoverse and is
              represented by a 2D and 3D digital map made up of thousands of hex
              tiles.
            </p>
            <img src={SuperPangeaImg} alt="" />
            <h3 id="overview-s22" className="content-label content-label-lv2">
              Pangea Regions
            </h3>
            <p className="content-text">
              The continent of Pangea is divided into various regions. Each
              region consists of a number of connected zones and districts that
              form a portion of the Pangea continent map. Pangea Regions will
              periodically open and become available to be claimed and territory
              expansion as the metaverse grows and develops.
            </p>
            <img src={PangeaRegionImg} alt="" />
            <h3 id="overview-s23" className="content-label content-label-lv2">
              Zones and Districts
            </h3>
            <p className="content-text">
              Zones represent the smallest individual tiles on the Continent map
              and comprise a mini-map that includes a unique terrain and
              landscape.
            </p>
            <p className="content-text">
              Each Zone will have a <strong>Starting Zone Terrain</strong> that
              will comprise three (3) or more different types of available
              Terrain. The basic Terrain types include:
            </p>
            <ul className="content-list content-list--disc">
              <li>
                <p>
                  <strong>Dark grey</strong> = mountains / cliff rocks
                  <strong> (unaccessible)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Dark blue</strong> = salt water / ocean
                  <strong> (unaccessible)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>White</strong> = ice / snow
                  <strong> (clearable)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Brown</strong> = clay / rocky/ rugged terrain
                  <strong> (clearable)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Dark green</strong> = dense vegetation / forest
                  <strong> (clearable)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Light green</strong> = plains / grass (hills, valleys)
                  <strong> (usable)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Light blue</strong> = fresh water / shallow water
                  (rivers, streams, lakes)
                  <strong> (usable)</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Light yellow</strong> = sand / desert (rivers,
                  <strong> (usable)</strong>
                </p>
              </li>
            </ul>
            <img src={ZoneDistrictImg} alt="" />
            <p className="content-text">
              Districts are collections of connected (2 or more) Zones that
              share a common landscape, functionality, purpose or classification
              and are depicted as a series of connected Zone Hex tiles.
            </p>
            <h3 id="overview-s24" className="content-label content-label-lv2">
              Plots and Land parcels
            </h3>
            <p className="content-text">
              Each Zone or District consists of a mini-map with land tiles
              called Plots. Plots are colour coded to indicate the type of
              terrain found in that piece of land (plot). There are 8 basic
              types of terrain (see above), which are divided into 3
              classifications:
            </p>

            <ul className="content-list content-list--disc">
              <li>
                <p>
                  <strong>Usable</strong> = plots of land that can be developed
                  and built upon without any additional work or requirements.
                </p>
              </li>
              <li>
                <p>
                  <strong>Clearable </strong> = plots of land that can be
                  developed and built upon once work has been done to convert
                  the plot into Usable land.
                </p>
              </li>
              <li>
                <p>
                  <strong>Inaccessible </strong> = plot of land that cannot be
                  used.
                </p>
              </li>
            </ul>
            <p className="content-text">
              Land Parcels represent areas of land that can be Claimed or
              Leased. The Land Parcel NFT is used to bid for Land{' '}
              <strong>Claims</strong> and Land <strong>Leases</strong> .
            </p>

            <ul className="content-list content-list--disc">
              <li>
                <p>
                  <strong>Land Claims</strong> = Areas of wilderness that can be
                  claimed by a player. Land Claimants are able to either build
                  on the Claimed Land Parcels or lease the Claimed Land Parcels
                  out to other players.
                </p>
              </li>
              <li>
                <p>
                  <strong>Land Leases</strong> = Land in Developed Zones or
                  Claimed Land in Wilderness Zones can be leased. Players can
                  lease land to establish a presence within a Zone or to
                  construct buildings or structures that provide resources or
                  other benefits.
                </p>
              </li>
            </ul>
            <p className="content-text">
              Land Parcels consist of 1 or more plots of land surrounded by a 1
              tile <strong>Area of Exclusion</strong> that acts as a buffer with
              neighbouring Land Parcels.
            </p>
            <h3 id="overview-s3" className="content-label">
              Introducing the Pangea Ecosystem
            </h3>
            <p className="content-text">
              Pangea will offer players the opportunity to participate in 3 core
              ecosystems:
            </p>
            <p className="content-label-lv2" id="overview-s31">
              Land Management
            </p>
            <p className="content-text">
              Land Management is an essential part of the Pangea metaverse and
              one of the three core ecosystems. Players will be able to:
            </p>
            <ul className="content-list content-list--alpha">
              <li>
                <p>Claim and develop new Land Parcels in Wilderness Zones</p>
              </li>
              <li>
                <p>Expand your territory claims</p>
              </li>
              <li>
                <p>Claim control over Zones and Districts</p>
              </li>
              <li>
                <p>
                  Lease Land Parcels in Developed Zones or from other players
                </p>
              </li>
              <li>
                <p>Build, customise and upgrade structures on Land Parcels</p>
              </li>
            </ul>
            <p className="content-label-lv2" id="overview-s32">
              Building Management
            </p>
            <p className="content-text">
              Dinoverse is collaborating with various other projects to bring a
              diverse range of buildings and structures to the Pangea Metaverse.
            </p>
            <p className="content-text">
              Players who have claimed or Leased Land Parcels will be able to
              purchase building and structure Blueprints from Blueprint stores
              located across Pangea. Blueprints enable Players to:
            </p>
            <ul className="content-list content-list--alpha">
              <li>
                <p>Construct a copy of the building or structure</p>
              </li>
              <li>
                <p>Upgrade the building or structure</p>
              </li>
              <li>
                <p>Customise the aesthetics of the building or structure </p>
              </li>
              <li>
                <p>
                  Add landscape items, decorations and collected NFT assets in
                  and around the buildings or structures
                </p>
              </li>
            </ul>
            <p className="content-label-lv2" id="overview-s33">
              Dino Management
            </p>
            <p className="content-text">
              Repopulating Dinos across Pangea is a core mission for Dinoverse
              Guardians, making the research, breeding and nurturing of Dinos
              are an important part of the Pangea ecosystem.
            </p>
            <p className="content-text">
              Dinos in Pangea go through 5 life stages:
            </p>
            <ul className="content-list content-list--disc">
              <li>
                <p>Eggs</p>
              </li>
              <li>
                <p>Baby Dinos</p>
              </li>
              <li>
                <p>Juvenile Dinos</p>
              </li>
              <li>
                <p>Mature Dinos</p>
              </li>
              <li>
                <p>Fallen Dinos</p>
              </li>
            </ul>
            <p className="content-text">Players will be able to:</p>
            <ul className="content-list content-list--disc">
              <li>
                <p>Research various Dino species, traits and attributes</p>
              </li>
              <li>
                <p>Breed and hatch Dino eggs</p>
              </li>
              <li>
                <p>Nurture baby and juvenile Dinos</p>
              </li>
              <li>
                <p>Train and enhance mature Dinos</p>
              </li>
              <li>
                <p>
                  Level up Mature Dinos and compete against other Dinos in
                  Battle Arenas
                </p>
              </li>
              <li>
                <p>Induce Mature Dinos to breed and spawn Eggs.</p>
              </li>
              <li>
                <p>Extract DNA and genetic material from Fallen Dinos.</p>
              </li>
            </ul>
            <p className="content-text">
              Battle Arenas: Battle Arenas are special Zones dedicated to
              enabling players to challenge and compete in PvP competitions and
              tournaments. Battles test the stats, abilities and training Dinos
              received, and help bring fame and fortune to players who are
              crowned as Champions.
            </p>
            {/* Gamestyle */}
            <h2 id="gamestyle" className="content-heading">
              Gamestyle
            </h2>
            <p id="gamestyle-section1" className="content-label">
              Play style
            </p>
            <p className="content-text">
              The Pangea Metaverse will primarily be played as a web browser
              based 3D virtual tabletop game comprising a mix of both 3D model
              and player trading card NFT assets.
            </p>
            <p className="content-text">
              The persistent Pangea Metaverse is divided into two levels:
            </p>
            <ul className="content-list content-list--disc">
              <li>
                <p>
                  The strategic global continent map of Pangea, which highlights
                  the Regions, Zones and Districts.
                </p>
              </li>
              <li>
                <p>
                  The local mini-map of each Zone and District, which provides a
                  view of the local territories, land parcels and terrain.
                </p>
              </li>
            </ul>
            <p className="content-text">
              Players will have access to an Account management section, that is
              linked to their Cardano Wallet (CCVault, Nami etc) and Discord
              accounts to the account.
            </p>
            <p id="gamestyle-section2" className="content-label">
              Player roles
            </p>
            <p className="content-text">
              Players will be able to select from a range of game options and
              roles based on the NFT assets and resources available in their
              account.
            </p>
            <p className="content-text">Players can select from:</p>
            <ul className="content-list content-list--disc">
              {[
                'Casual Dino raising mini-game',
                'Land claiming and ranking',
                'Resource management',
                'NFT trading',
                'Exploration mode',
                'Virtual designer / builder',
                'Dino Trainer / Coach',
                'Competitive Battle Team Management',
              ].map((el) => (
                <li>
                  <p>{el}</p>
                </li>
              ))}
            </ul>
            <p id="gamestyle-section3" className="content-label">
              Player ecosystem
            </p>
            <p className="content-text">
              Players will be able to participate in global and local
              activities, based on the Regions, Zones and Districts that they
              are part of.
            </p>
            <p className="content-text">
              Players within a Zone or District will be able to interact with
              each other and trade directly in game.
            </p>
            <p className="content-text">
              Players in different Zones or Districts will need to request
              transportation services or queue to transfer resources between
              Zones and Districts.
            </p>
            {/* Technology */}
            <h2 id="technology" className="content-heading">
              Technology direction and Roadmap
            </h2>
            <p id="technology-section1" className="content-label">
              Why build a Cardano NFT metaverse
            </p>
            <p className="content-text">
              Cardano has introduced a novel extended Unspent Transaction Output
              (eUTXO) model that builds on the foundations introduced by the
              Bitcoin blockchain (
              <a
                href="https://iohk.io/en/research/library/papers/the-extended-utxo-model/"
                className="content-text__link"
                target="_blank"
                rel="noreferrer"
              >
                https://iohk.io/en/research/library/papers/the-extended-utxo-model/
              </a>
              ). In addition, the Cardano blockchain introduced Javascript
              Object Notation (JSON) advanced transaction metadata, which
              enabled a community driven Cardano NFT ecosystem to emerge,
              without the need for smart contracts.
            </p>
            <p className="content-text">
              Dinoverse is a Cardano blockchain-based, NFT (non-fungible token)
              driven digital metaverse that centres around the theme of bringing
              dynamic 3D animated dinosaurs and various mythical creatures to
              life. As the Cardano blockchain ecosystem introduces advanced
              functionality, the Dinoverse team seeks to demonstrate new
              capabilities available through Cardano NFT (CNFT) technology and
              expand the Cardano metaverse to attract broader adoption of the
              technology across both retail and commercial applications.
            </p>
            <p id="technology-section2" className="content-label">
              Dinoverse NFTs and meta-game mechanics
            </p>
            <p className="content-text">
              Building on these technological foundations, Dinoverse seeks to
              push these technological boundaries even further by demonstrating
              new, innovative and advanced NFT functionality in the Cardano NFT
              ecosystem. As an example, Dinoverse was one of the first projects
              to introduce interactive, animated 3D model NFTs on the Cardano
              blockchain, with the release of the T-Rex series of Dino NFTs in
              November 2021 (
              <a
                href="https://pool.pm/926075ba72037da019e28565bd379662202132723dc10bd13ffc6850.TREX0001"
                className="content-text__link"
                target="_blank"
                rel="noreferrer"
              >
                https://pool.pm/926075ba72037da019e28565bd379662202132723dc10bd13ffc6850.TREX0001
              </a>
              ).
            </p>
            <p className="content-text">
              The Dinoverse team is excited to be bringing to the Cardano NFT
              community a rich, immersive and interactive 3D metaverse, with
              virtual land and pocket worlds inspired by the ancient Pangea land
              mass.
            </p>
            <p className="content-text">
              Dinoverse will offer all players access to explore and download
              copies of the 3D models of the Dino NFTs and Pangea Metaverse
              Assets.
            </p>
            <p className="content-text">
              The Pangea metaverse will bring an exciting new model, that
              combines elements of:
            </p>
            <ul className="content-list content-list--disc">
              {[
                'Metarights and metadata driven digital asset rights management',
                'Player owned sandbox virtual world building',
                'Smart contract based peer-to-peer asset trading marketplace',
                'And much more.',
              ].map((el) => (
                <li>
                  <p>{el}</p>
                </li>
              ))}
            </ul>
            <h2 id="technology-section3" className="content-label">
              Technology Roadmap
            </h2>
            <br />
            <br />
            <br />
            <br />
          </S.Content>
          <S.Search>
            <S.SearchBox>
              <h3>Search in page</h3>
              <input
                ref={inputRef}
                type="text"
                placeholder="Typing here..."
                // onKeyDown={(e) => handleSearch(e)}
              />
            </S.SearchBox>
          </S.Search>
        </S.ContentWrapper>
      </S.MainWrapper>
    </S.Wrapper>
  );
}

export default Lite;
