import React from 'react';

import Fullpage from '../../components/Fullpage';
import HomePage from '../HomePage';
import About from '../About';
import RoadMap from '../RoadMap';
import Pangea from '../Pangea';

import Collection from '../Collection';

import * as S from './styled';

function WrapPage() {
  return (
    <Fullpage normalScrollElements="#collection">
      <S.Page className="section">
        <HomePage />
      </S.Page>

      <S.Page className="section">
        <About />
      </S.Page>

      <S.Page className="section">
        <RoadMap />
      </S.Page>

      <S.Page className="section">
        <Collection />
      </S.Page>

      <S.Page className="section">
        <Pangea />
      </S.Page>
    </Fullpage>
  );
}

export default WrapPage;
