const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  currentUser: {},
  isAuth: false,
  isFirstAccess: true,
  loading: false,
  error: {},
  tab: '1',
  section: 1,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    tab: (state, action) => {
      state.tab = action.payload;
    },
    section: (state, action) => {
      state.section = action.payload;
    },
  },
});

export const { tab, section } = authSlice.actions;
const { reducer: authReducer } = authSlice;
export default authReducer;
