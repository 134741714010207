import styled, { css } from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  font-family: Montserrat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.phone`
    justify-content: flex-start;
    padding: 0 20px;
  `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const Heading = styled.h1`
  margin-bottom: 8px;
  font-weight: 900;
  font-style: normal;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#516462, #23312f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.smallDesktop`
    font-size: 40px;
  `}
  ${media.phone`
    margin-top: 130px;
    font-size: 36px;
  `}
`;

export const SubHeading = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: #222f2d;
  margin-bottom: 24px;
  ${media.smallDesktop`
    font-size: 14px;
  `}
  ${media.phone`
    font-size: 13px;
  `}
`;

export const Line = styled.div`
  margin-bottom: 64px;
  width: 79px;
  height: 2px;
  background-color: #4f9f14;
  ${media.phone`
    width: 69px;
  `}
`;

export const Description = styled.p`
  max-width: 600px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  color: #222f2d;
  margin-bottom: 20px;
  ${media.smallDesktop`
    font-size: 16px;
  `}
  ${media.smallDesktop`
    font-size: 14px;
  `}
`;
