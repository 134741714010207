import styled, { css } from 'styled-components';

export const MenuItem = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;

  font-size: ${({ $fontSize }) => $fontSize || `26px`};
  line-height: ${({ $lineHeight }) => $lineHeight || `32px`};
  color: ${({ $color }) => $color || `#425452`};
`;

export const Heading1 = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 1px;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
`;

export const Heading2 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 1px;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};

  ${({ isBold, theme }) =>
    css`
      font-weight: ${isBold ? theme.fontWeight.extraBold : theme.fontWeight.bold};
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
`;

export const Text = styled.p`
  line-height: 24px;
  font-size: 14px;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
      font-size: 16px;
    `}
`;
