/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei';

export default function ModelHomePage(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/modelHome.glb');
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions.Animation.play();
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Empty" position={[-0.02, 1.01, -0.01]} scale={0.88}>
          <group
            name="rex_rig"
            position={[0, 0, -0.05]}
            rotation={[1.57, -1.53, 1.57]}
            scale={0.14}
          >
            <primitive object={nodes.Root} />
            <skinnedMesh
              castShadow
              receiveShadow
              name="rex_body"
              geometry={nodes.rex_body.geometry}
              material={materials.tyrannosaurus}
              skeleton={nodes.rex_body.skeleton}
            />
          </group>
        </group>
        <group name="Empty001" position={[0, 0, 0]} rotation={[0, 0, -0.62]} scale={1.55}>
          <group
            name="raptor_rig"
            position={[-0.64, 0, 0]}
            rotation={[0, 0, 1.55]}
            scale={[0.06, 0.06, 0.06]}
          >
            <primitive object={nodes.main} />
            <group name="raptor_mesh">
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube007"
                geometry={nodes.Cube007.geometry}
                material={nodes.Cube007.material}
                skeleton={nodes.Cube007.skeleton}
              />
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube007_1"
                geometry={nodes.Cube007_1.geometry}
                material={nodes.Cube007_1.material}
                skeleton={nodes.Cube007_1.skeleton}
              />
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube007_2"
                geometry={nodes.Cube007_2.geometry}
                material={nodes.Cube007_2.material}
                skeleton={nodes.Cube007_2.skeleton}
              />
            </group>
          </group>
          <group
            name="rig001"
            position={[-0.62, -0.09, -0.15]}
            rotation={[0.23, -0.21, 1.74]}
            scale={0.06}
          >
            <primitive object={nodes.main_1} />
            <group name="raptor_mesh001">
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube004"
                geometry={nodes.Cube004.geometry}
                material={nodes.Cube004.material}
                skeleton={nodes.Cube004.skeleton}
              />
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube004_1"
                geometry={nodes.Cube004_1.geometry}
                material={nodes.Cube004_1.material}
                skeleton={nodes.Cube004_1.skeleton}
              />
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube004_2"
                geometry={nodes.Cube004_2.geometry}
                material={nodes.Cube004_2.material}
                skeleton={nodes.Cube004_2.skeleton}
              />
            </group>
          </group>
          <group
            name="rig002"
            position={[-0.57, 0.12, -0.27]}
            rotation={[0.23, -0.46, 1.46]}
            scale={0.06}
          >
            <primitive object={nodes.main_2} />
            <group name="raptor_mesh002">
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube009"
                geometry={nodes.Cube009.geometry}
                material={nodes.Cube009.material}
                skeleton={nodes.Cube009.skeleton}
              />
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube009_1"
                geometry={nodes.Cube009_1.geometry}
                material={nodes.Cube009_1.material}
                skeleton={nodes.Cube009_1.skeleton}
              />
              <skinnedMesh
                castShadow
                receiveShadow
                name="Cube009_2"
                geometry={nodes.Cube009_2.geometry}
                material={nodes.Cube009_2.material}
                skeleton={nodes.Cube009_2.skeleton}
              />
            </group>
          </group>
        </group>
        <group
          name="Camera002"
          position={[-8.55, 4.4, 3.64]}
          rotation={[1.54, -0.07, 1.16]}
          scale={0.25}
        >
          <PerspectiveCamera
            name="Camera002_Orientation"
            makeDefault={false}
            far={100}
            near={0.1}
            fov={21.03}
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <mesh
          name="Icosphere"
          castShadow
          receiveShadow
          geometry={nodes.Icosphere.geometry}
          material={materials['Material.001']}
          position={[0, 0, 0]}
        >
          <mesh
            name="Icosphere001"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere001.geometry}
            material={nodes.Icosphere001.material}
            position={[-0.18, 1.29, 0.43]}
            rotation={[0.51, -1.1, 0.32]}
            scale={[0.71, 0.71, 0.71]}
          />
          <mesh
            name="Icosphere005"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere005.geometry}
            material={nodes.Icosphere005.material}
            position={[0.05, -0.03, 1.27]}
            rotation={[-0.07, -1.39, -1.79]}
            scale={[0.76, 0.76, 0.76]}
          />
          <mesh
            name="Icosphere013"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere013.geometry}
            material={nodes.Icosphere013.material}
            position={[0.66, -0.18, -1.22]}
            rotation={[-1.4, 0.86, -0.52]}
            scale={[0.87, 0.87, 0.87]}
          />
          <mesh
            name="Icosphere017"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere017.geometry}
            material={nodes.Icosphere017.material}
            position={[-1.03, -0.28, -0.7]}
            rotation={[-2.3, 0.04, 0.95]}
            scale={[0.93, 0.93, 0.93]}
          />
          <mesh
            name="Icosphere004"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere004.geometry}
            material={nodes.Icosphere004.material}
            position={[-0.2, 1.26, 0.62]}
            rotation={[0.38, -1.08, 0.13]}
            scale={[0.51, 0.51, 0.51]}
          />
          <mesh
            name="Icosphere006"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere006.geometry}
            material={nodes.Icosphere006.material}
            position={[-0.76, -0.83, 0.77]}
            rotation={[-2.69, -0.85, 0.92]}
            scale={0.65}
          />
          <mesh
            name="Icosphere008"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere008.geometry}
            material={nodes.Icosphere008.material}
            position={[-0.13, -0.54, 1.2]}
            rotation={[0.04, -1.45, -1.83]}
            scale={[0.63, 0.63, 0.63]}
          />
          <mesh
            name="Icosphere010"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere010.geometry}
            material={nodes.Icosphere010.material}
            position={[1, -0.27, 0.78]}
            rotation={[-0.45, -0.36, -2.26]}
            scale={[0.94, 0.94, 0.94]}
          />
          <mesh
            name="Icosphere012"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere012.geometry}
            material={nodes.Icosphere012.material}
            position={[-0.15, -1.13, -0.69]}
            rotation={[-2.78, -0.85, -0.02]}
            scale={[0.81, 0.81, 0.81]}
          />
          <mesh
            name="Icosphere016"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere016.geometry}
            material={nodes.Icosphere016.material}
            position={[1, 0.68, -0.58]}
            rotation={[-0.7, 0.31, -0.7]}
            scale={[0.63, 0.63, 0.63]}
          />
          <mesh
            name="Icosphere020"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere020.geometry}
            material={nodes.Icosphere020.material}
            position={[-0.96, -0.02, -0.83]}
            rotation={[-2.06, 0.09, 1.05]}
            scale={[0.65, 0.65, 0.65]}
          />
          <mesh
            name="Icosphere007"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere007.geometry}
            material={nodes.Icosphere007.material}
            position={[-0.23, 0.2, 1.22]}
            rotation={[-0.12, -1.41, -1.6]}
            scale={[0.7, 0.7, 0.7]}
          />
          <mesh
            name="Icosphere011"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere011.geometry}
            material={nodes.Icosphere011.material}
            position={[0.06, -1.2, -0.8]}
            rotation={[1.13, -0.23, -2.6]}
            scale={[0.64, 0.64, 0.64]}
          />
          <mesh
            name="Icosphere015"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere015.geometry}
            material={nodes.Icosphere015.material}
            position={[0.91, -0.2, -1.05]}
            rotation={[-1.12, 1.07, -0.95]}
            scale={[0.81, 0.81, 0.81]}
          />
          <mesh
            name="Icosphere019"
            castShadow
            receiveShadow
            geometry={nodes.Icosphere019.geometry}
            material={nodes.Icosphere019.material}
            position={[-1.16, -0.13, -0.44]}
            rotation={[-2.19, -0.3, 1.08]}
            scale={[0.7, 0.7, 0.7]}
          />
          <group
            name="tree"
            position={[-0.87, 0.59, -0.01]}
            rotation={[-1.14, 0.36, 1.32]}
            scale={[0.61, 0.5, 0.61]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree001"
            position={[-1.01, 0.1, -0.27]}
            rotation={[2.91, 0.08, 1.61]}
            scale={[0.65, 0.47, 0.65]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree002"
            position={[-0.63, -0.82, 0.16]}
            rotation={[1.29, 0.98, 1.66]}
            scale={[0.96, 0.7, 0.96]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree003"
            position={[-0.77, 0.71, 0.06]}
            rotation={[3.11, -0.25, 2]}
            scale={[0.74, 0.61, 0.74]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree004"
            position={[-0.67, 0.84, -0.05]}
            rotation={[0.95, -0.78, 1.29]}
            scale={[0.81, 0.67, 0.81]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree005"
            position={[-0.75, 0.71, -0.19]}
            rotation={[-0.2, -0.38, 0.86]}
            scale={[0.81, 0.67, 0.81]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree006"
            position={[-0.73, 0.68, -0.33]}
            rotation={[2.2, -0.37, 2.41]}
            scale={[0.78, 0.65, 0.78]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree007"
            position={[-0.73, 0.74, -0.09]}
            rotation={[0.12, -0.14, 0.9]}
            scale={[0.98, 0.81, 0.98]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree008"
            position={[-0.63, 0.84, 0.11]}
            rotation={[0.61, -0.38, 1.01]}
            scale={[0.83, 0.69, 0.83]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree009"
            position={[-0.74, 0.69, 0.24]}
            rotation={[-0.91, 0.52, 1.53]}
            scale={[0.81, 0.67, 0.81]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree011"
            position={[-0.71, 0.78, 0.03]}
            rotation={[1.78, -0.75, 1.68]}
            scale={[0.73, 0.61, 0.73]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree012"
            position={[-0.71, 0.77, 0.16]}
            rotation={[0.64, -0.11, 0.97]}
            scale={[0.87, 0.72, 0.87]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree013"
            position={[-0.55, 0.8, 0.38]}
            rotation={[0.29, 0.58, 0.64]}
            scale={[0.74, 0.61, 0.74]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree014"
            position={[-0.71, 0.65, 0.37]}
            rotation={[2.49, -0.74, 1.8]}
            scale={[0.61, 0.5, 0.61]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree015"
            position={[-0.65, 0.67, 0.48]}
            rotation={[3.11, -0.71, 2.12]}
            scale={[0.75, 0.62, 0.75]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree016"
            position={[-0.58, 0.61, 0.6]}
            rotation={[-0.07, 0.73, 1.05]}
            scale={[0.58, 0.48, 0.58]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree017"
            position={[-0.75, 0.53, -0.51]}
            rotation={[1.69, -0.6, 2.32]}
            scale={[0.67, 0.48, 0.67]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree018"
            position={[-0.15, 0.14, 1.03]}
            rotation={[1.61, -0.74, 0.27]}
            scale={[0.7, 0.46, 0.7]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree019"
            position={[-0.77, 0.57, -0.41]}
            rotation={[-2.91, 0.64, 2.07]}
            scale={[0.69, 0.49, 0.69]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree020"
            position={[-0.57, 0.51, 0.71]}
            rotation={[0.92, 0.41, 0.74]}
            scale={[0.74, 0.61, 0.74]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree021"
            position={[-1.01, -0.01, -0.3]}
            rotation={[1.48, -0.08, 1.78]}
            scale={[0.86, 0.64, 0.86]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree022"
            position={[-0.91, 0.09, -0.48]}
            rotation={[1.89, 0.13, 2.15]}
            scale={[0.85, 0.64, 0.85]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree023"
            position={[-0.86, 0.18, -0.57]}
            rotation={[1.89, 0.13, 2.15]}
            scale={[0.6, 0.44, 0.6]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree024"
            position={[-0.91, 0.22, -0.45]}
            rotation={[1.96, -0.03, 1.96]}
            scale={[0.73, 0.54, 0.73]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree025"
            position={[-0.97, 0.12, -0.35]}
            rotation={[-3.01, 0.37, 1.7]}
            scale={[0.85, 0.71, 0.85]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree026"
            position={[-0.96, -0.04, -0.41]}
            rotation={[-3.02, 0.47, 1.59]}
            scale={[0.95, 0.7, 0.95]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree027"
            position={[-0.99, 0.2, -0.22]}
            rotation={[-3, 0.2, 1.79]}
            scale={[0.72, 0.53, 0.72]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree028"
            position={[-1.03, 0.06, -0.18]}
            rotation={[1.6, -0.25, 1.59]}
            scale={[0.79, 0.58, 0.79]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree029"
            position={[-0.96, 0.27, -0.33]}
            rotation={[1.98, -0.15, 1.97]}
            scale={[0.68, 0.56, 0.68]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree030"
            position={[-0.86, 0.44, -0.39]}
            rotation={[1.99, -0.21, 2.03]}
            scale={[0.58, 0.45, 0.58]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree031"
            position={[-0.73, -0.75, 0.11]}
            rotation={[-2.94, -0.1, 0.88]}
            scale={[0.72, 0.6, 0.72]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree032"
            position={[-0.77, -0.66, 0.27]}
            rotation={[-3.05, -0.23, 0.96]}
            scale={[0.72, 0.53, 0.72]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree033"
            position={[-0.69, -0.71, 0.31]}
            rotation={[1.63, 0.62, 1.25]}
            scale={[0.79, 0.58, 0.79]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree034"
            position={[-0.7, -0.74, 0.21]}
            rotation={[2.94, 0.03, 0.71]}
            scale={[0.58, 0.47, 0.58]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree035"
            position={[-0.56, -0.78, 0.4]}
            rotation={[0.51, 0.89, 2.07]}
            scale={[0.67, 0.58, 0.79]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree036"
            position={[-0.69, -0.67, 0.42]}
            rotation={[1.09, 0.98, 1.6]}
            scale={[0.88, 0.76, 1.03]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree037"
            position={[-0.52, -0.72, 0.54]}
            rotation={[0.55, 1, 1.88]}
            scale={[0.82, 0.81, 0.96]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree038"
            position={[-0.64, -0.8, -0.15]}
            rotation={[-2.94, -0.09, 0.68]}
            scale={[0.88, 0.73, 0.88]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree039"
            position={[-0.65, -0.81, -0.02]}
            rotation={[1.56, 0.95, 1.55]}
            scale={[0.94, 0.7, 0.94]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree040"
            position={[-0.75, -0.73, -0.08]}
            rotation={[-2.94, -0.1, 0.88]}
            scale={[0.81, 0.67, 0.81]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree041"
            position={[-0.79, -0.64, 0.14]}
            rotation={[-3.05, -0.23, 0.96]}
            scale={[0.94, 0.69, 0.94]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree042"
            position={[-0.82, -0.64, 0.01]}
            rotation={[-2.87, 0.08, 0.98]}
            scale={[0.85, 0.62, 0.85]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree043"
            position={[-0.72, -0.7, -0.26]}
            rotation={[-2.91, 0.01, 0.9]}
            scale={[0.81, 0.67, 0.81]}
          >
            <mesh
              name="Cylinder"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder.geometry}
              material={nodes.Cylinder.material}
            />
            <mesh
              name="Cylinder_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder_1.geometry}
              material={nodes.Cylinder_1.material}
            />
          </group>
          <group
            name="tree044"
            position={[-0.29, 0.99, 0.01]}
            rotation={[2.4, -0.78, 2.24]}
            scale={[0.74, 0.61, 0.74]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree045"
            position={[-0.18, 1.02, 0.09]}
            rotation={[2.9, -1.16, 2.71]}
            scale={[0.83, 0.68, 0.83]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree047"
            position={[-0.08, 0.94, 0.45]}
            rotation={[-2.85, -1.25, 2.86]}
            scale={[0.85, 0.7, 0.85]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree048"
            position={[-0.15, 0.99, 0.21]}
            rotation={[-3.09, -1.2, 3.06]}
            scale={[1, 0.83, 1]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree049"
            position={[-0.25, 0.99, -0.08]}
            rotation={[2.25, -1.04, 2.4]}
            scale={[0.96, 0.72, 0.96]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree050"
            position={[-0.13, 1, 0.32]}
            rotation={[3.03, -1.36, 2.74]}
            scale={[0.91, 0.76, 0.91]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree051"
            position={[-0.62, 0.74, 0.47]}
            rotation={[2.99, -0.85, 2.15]}
            scale={[1.04, 0.86, 1.04]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree052"
            position={[-0.61, 0.82, 0.26]}
            rotation={[2.43, -0.73, 2.09]}
            scale={[0.85, 0.7, 0.85]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
          <group
            name="tree053"
            position={[-0.44, 0.72, 0.67]}
            rotation={[-2.78, -0.93, 2.64]}
            scale={[0.87, 0.72, 0.87]}
          >
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={nodes.Cylinder001.material}
            />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={nodes.Cylinder001_1.material}
            />
          </group>
        </mesh>
        <mesh
          name="Dinoverse_logo001"
          castShadow
          receiveShadow
          geometry={nodes.Dinoverse_logo001.geometry}
          material={materials.Dinoverse_logo}
          position={[0, 0.34, 0]}
          rotation={[0, 0.33, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/modelHome.glb');
