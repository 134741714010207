/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Button from '../../components/commons/Button';

import { section } from '../../store/authSlice';

import { ROUTES } from '../../routes/constants';

import * as S from './styled';

import mockupData from './mockup-data';
import trex from './data.json';

ChartJS.register(ArcElement, Tooltip, Legend);

function CollectionDetail() {
  const [numberItem, setNumberItem] = useState(10);
  const toggleReadMore = (event) => {
    let newNumberItem = numberItem + 10;
    if (newNumberItem > trex?.gif?.length) {
      newNumberItem = trex?.gif?.length;
    }
    setNumberItem(newNumberItem);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const dataGenesis = [
    { data: 5, background: '#E71E1E', legendLabel: 'epic' },
    { data: 15, background: '#F19325', legendLabel: 'rare' },
    { data: 30, background: '#3051B7', legendLabel: 'uncommon' },
    { data: 50, background: '#5EB661', legendLabel: 'common' },
  ];
  const dataPublic = [
    { data: 350, background: '#F336B3', legendLabel: 'static dinos' },
    { data: 150, background: '#892EE3', legendLabel: 'animated dinos' },
    { data: 30, background: '#0BB0E4', legendLabel: 'special animated' },
  ];

  const mockData = [
    {
      thumbnail: mockupData.Gif1,
      name: 'Tyrannosaurus',
    },
    {
      thumbnail: mockupData.Gif2,
      name: 'Tyrannosaurus',
    },
    {
      thumbnail: mockupData.Gif3,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif4,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif5,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif6,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif7,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif8,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif9,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif10,
      name: 'Tyrannosaurus',
      isSoldOut: true,
    },
  ];
  const genesisChartData = {
    datasets: [
      {
        data: dataGenesis.map((value) => value.data),
        backgroundColor: dataGenesis.map((value) => value.background),
        borderWidth: 0,
        rotation: -90,
        cutout: '79%',
      },
    ],
  };
  const publicChartData = {
    datasets: [
      {
        data: dataPublic.map((value) => value.data),
        backgroundColor: dataPublic.map((value) => value.background),
        borderWidth: 0,
        rotation: -90,
        cutout: '79%',
      },
    ],
  };
  return (
    <S.Wrapper backgroundImage={mockupData.Background}>
      <S.WrapLogo
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(section(1));
          history.push(ROUTES.HOME);
        }}
      >
        <img src={mockupData.logo} alt="" />
      </S.WrapLogo>
      <S.WrapDetail>
        <div className="thumbnail-wrap">
          <div
            className="btn-back"
            onClick={() => {
              dispatch(section(4));
              history.push(ROUTES.HOME);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={mockupData.LeftArrow} alt="" />
            Back
          </div>
          <img src={mockupData.Tyranno} alt="" className="thumbnail-img" />
        </div>
        <S.Detail>
          <S.DetailHeading>T-Rex fact-sheet</S.DetailHeading>
          <S.DetailLabel>Interesting Facts</S.DetailLabel>
          <S.DetailContent>
            The name Tyrannosaurus, comes from the Greek word meaning tyrant lizard. The T-Rex was
            one of the largest meat-eaters and could measure up to 12 meters in length. When
            sprinting, this dino could reach speeds of up to 32 kilometers per hour!
          </S.DetailContent>
          <S.DetailLabel>Drop Details</S.DetailLabel>
          <S.DetailContent>
            <ul>
              <li>
                The T-Rex drop included 2 collections:
                <ul>
                  <li>100 unique genesis T-Rex dinos.</li>
                  <li>500 public sale T-Rex dinos.</li>
                </ul>
              </li>
              <li>This was Dinoverse’s first drop</li>
              <li>
                All T-Rex holders will receive an airdrop Pangea land parcel NFT once the Metaverse
                is launched.
              </li>
            </ul>
            <div className="button-visit">
              <Button
                className="button-trex"
                style={{
                  background: 'linear-gradient(90deg, #F9931A 0%, #D35610 99.99%)',
                  display: 'none',
                }}
                label="VISIT SALE PAGE"
                onClick={() => history.push(ROUTES.SALE)}
              />
            </div>
          </S.DetailContent>
        </S.Detail>
      </S.WrapDetail>
      <S.WrapTabs>
        <div className="tabs">
          <S.Tab animated={{ inkBar: false, tabPane: true }} centered defaultActiveKey="1">
            <S.Tab.TabPane tab="PREVIEWS" key="1" className="content">
              <S.Preview>
                {trex.gif.slice(0, numberItem).map((item, index) => (
                  <S.PreviewItem>
                    <S.PreviewItemThumbnail>
                      <img src={item.data} className="preview-dino" alt="" />
                      {item?.isSoldOut && (
                        <img src={mockupData.SoldOut} alt="" className="sold-out" />
                      )}
                    </S.PreviewItemThumbnail>
                    <img src={mockupData.Stand} className="preview-stand" alt="img"></img>
                    <p className="preview-name">{item.name}</p>
                  </S.PreviewItem>
                ))}
              </S.Preview>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {numberItem < trex?.gif?.length && (
                  <Button label="LOAD MORE" onClick={toggleReadMore} />
                )}
              </div>
            </S.Tab.TabPane>
            <S.Tab.TabPane tab="rarity chart" key="2" className="content">
              <S.WrapChart>
                <S.WrapChartItem>
                  <S.ChartBox>
                    <Doughnut data={genesisChartData}></Doughnut>
                    <div className="chart__text">
                      <p className="chart__text-title">T-Rex Genesis Series</p>
                      <p className="chart__text-subtitle">The first of many</p>
                    </div>
                  </S.ChartBox>
                  <S.ChartLegend>
                    {dataGenesis.map((value) => (
                      <S.ChartLegendItem background={value.background}>
                        <p className="legend-label">{value.legendLabel}</p>
                        <p className="legend-percent">{`${value.data}%`}</p>
                      </S.ChartLegendItem>
                    ))}
                  </S.ChartLegend>
                </S.WrapChartItem>
                <div className="vertical-line"></div>
                <S.WrapChartItem flexDirection="row-reverse">
                  <S.ChartBox>
                    <Doughnut data={publicChartData}></Doughnut>
                    <div className="chart__text">
                      <p className="chart__text-title">T-Rex Public Series</p>
                      <p className="chart__text-subtitle">The first of many</p>
                    </div>
                  </S.ChartBox>
                  <S.ChartLegend>
                    {dataPublic.map((value) => (
                      <S.ChartLegendItem background={value.background}>
                        <p className="legend-label">{value.legendLabel}</p>
                        <p className="legend-percent">{`${value.data}`}</p>
                      </S.ChartLegendItem>
                    ))}
                  </S.ChartLegend>
                </S.WrapChartItem>
              </S.WrapChart>
            </S.Tab.TabPane>
          </S.Tab>
        </div>
      </S.WrapTabs>
    </S.Wrapper>
  );
}

export default CollectionDetail;
