/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { section } from '../../store/authSlice';

import { ROUTES } from '../../routes/constants';

import Button from '../../components/commons/Button';

import * as S from '../CollectionDetail/styled';

import mockupData from './mockup-data';
import raptor from './data.json';

ChartJS.register(ArcElement, Tooltip, Legend);

function CollectionDetail() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ybVisible, setYBVisible] = useState(false);
  const [numberItem, setNumberItem] = useState(10);
  const toggleReadMore = (event) => {
    let newNumberItem = numberItem + 10;
    if (newNumberItem > raptor?.gif?.length) {
      newNumberItem = raptor?.gif?.length;
    }
    setNumberItem(newNumberItem);
  };

  const mockData = [
    {
      thumbnail: mockupData.Gif1,
      name: 'Raptors',
    },
    {
      thumbnail: mockupData.Gif2,
      name: 'Raptors',
    },
    {
      thumbnail: mockupData.Gif3,
      name: 'Raptors',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif4,
      name: 'Raptors',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif5,
      name: 'Raptors',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif6,
      name: 'Raptors',
    },
    {
      thumbnail: mockupData.Gif7,
      name: 'Raptors',
    },
    {
      thumbnail: mockupData.Gif8,
      name: 'Raptors',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif9,
      name: 'Raptors',
      isSoldOut: true,
    },
    {
      thumbnail: mockupData.Gif10,
      name: 'Raptors',
      isSoldOut: true,
    },
  ];
  const dataGenesis = [
    { data: 5, background: '#E71E1E', legendLabel: 'epic' },
    { data: 15, background: '#F19325', legendLabel: 'rare' },
    { data: 30, background: '#3051B7', legendLabel: 'uncommon' },
    { data: 50, background: '#5EB661', legendLabel: 'common' },
  ];
  const genesisChartData = {
    datasets: [
      {
        data: dataGenesis.map((value) => value.data),
        backgroundColor: dataGenesis.map((value) => value.background),
        borderWidth: 0,
        rotation: -90,
        cutout: '79%',
      },
    ],
  };
  return (
    <S.Wrapper backgroundImage={mockupData.Background}>
      <S.WrapLogo
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(section(1));
          history.push(ROUTES.HOME);
        }}
      >
        <img src={mockupData.logo} alt="" />
      </S.WrapLogo>
      <S.WrapDetail>
        <div className="thumbnail-wrap">
          <div
            className="btn-back"
            onClick={() => {
              dispatch(section(4));
              history.push(ROUTES.HOME);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={mockupData.LeftArrow} alt="" />
            Back
          </div>
          <img src={mockupData.Raptor} alt="" className="thumbnail-img" />
        </div>
        <S.Detail>
          <S.DetailHeading>Raptors fact-sheet</S.DetailHeading>
          <S.DetailLabel>Interesting Facts</S.DetailLabel>
          <S.DetailContent>
            The Utahraptor is considered the world's largest raptor, and could weigh up to a tonne.
            These dinosaurs were covered in feathers and their hind feet 'single claws were one feet
            long! These guys were by far the most dangerous raptors that ever lived!
          </S.DetailContent>
          <S.DetailLabel>Drop Details</S.DetailLabel>
          <S.DetailContent>
            <ul>
              <li>
                The Raptors drop is a collection of
                <strong> 950 unique 3D animated raptors </strong>
                that will be released into the Dinoverse this
                <span className="strong-text"> Sunday 6th of March, 2022 UTC 00:00.</span>
              </li>
              <li>
                <span className="strong-text">This is the first Dinoverse drop </span>
                to introduce female-male and juvenile-adult attributes in for our dinosaur NFTs
                <ul>
                  <li>
                    150 <strong>rare</strong> adult female and male fully feathered raptors.
                  </li>
                  <li>
                    300 <strong>uncommon</strong> juvenile partially feathered female and male
                    raptors.
                  </li>
                  <li>
                    500 <strong>common</strong> juvenile partially feathered female and male
                    raptors.
                  </li>
                </ul>
              </li>

              <li>
                This one will be a <strong>fully 3D animated drop</strong> for everyone in the
                community to have a high quality 3D animation NFT in their own hands.
              </li>
              <li>
                Raptor holders will be airdropped and receive one Pangea land NFT once the Metaverse
                is launched.
              </li>
            </ul>
          </S.DetailContent>
          <div className="button-visit">
            <a href={ROUTES.SALE_COMING_SOON} target="_blank" rel="noreferrer">
              <Button
                className="button-raptor"
                style={{
                  background: 'linear-gradient(90deg, #F9931A 0%, #D35610 99.99%)',
                  marginRight: '20px',
                }}
                label="VISIT SALE PAGE"
              />
            </a>
            <Button
              className="button-raptor"
              label="WATCH TRAILER"
              onClick={() => setYBVisible(true)}
            />
          </div>
        </S.Detail>
      </S.WrapDetail>
      <S.WrapTabs>
        <div className="tabs">
          <S.Tab animated={{ inkBar: false, tabPane: true }} centered defaultActiveKey="1">
            <S.Tab.TabPane tab="PREVIEWS" key="1" className="content">
              <S.Preview>
                {raptor?.gif?.slice(0, numberItem).map((item, index) => (
                  <S.PreviewItem key={index}>
                    <S.PreviewItemThumbnail>
                      <img src={item.data} className="preview-dino" alt="" />
                      {item?.isSoldOut && (
                        <img src={mockupData.SoldOut} alt="" className="sold-out" />
                      )}
                    </S.PreviewItemThumbnail>
                    <img src={mockupData.Stand} className="preview-stand" alt="img"></img>
                    <p className="preview-name">{item.name}</p>
                  </S.PreviewItem>
                ))}
              </S.Preview>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {numberItem < raptor?.gif?.length && (
                  <Button label="LOAD MORE" onClick={toggleReadMore} />
                )}
              </div>
            </S.Tab.TabPane>
            <S.Tab.TabPane tab="rarity chart" key="2" className="content">
              <S.WrapChart>
                <S.WrapChartItem>
                  <S.ChartBox>
                    <Doughnut data={genesisChartData}></Doughnut>
                    <div className="chart__text">
                      <p className="chart__text-title">Raptors Series</p>
                      <p className="chart__text-subtitle">The first of many</p>
                    </div>
                  </S.ChartBox>
                  <S.ChartLegend>
                    {dataGenesis.map((value) => (
                      <S.ChartLegendItem background={value.background}>
                        <p className="legend-label">{value.legendLabel}</p>
                        <p className="legend-percent">{`${value.data}%`}</p>
                      </S.ChartLegendItem>
                    ))}
                  </S.ChartLegend>
                </S.WrapChartItem>
              </S.WrapChart>
            </S.Tab.TabPane>
          </S.Tab>
        </div>
      </S.WrapTabs>
      <S.ModalYoutube
        centered
        visible={ybVisible}
        onCancel={() => setYBVisible(false)}
        footer={null}
      >
        <iframe
          title="foo"
          width="1010"
          height="568"
          src="https://www.youtube.com/embed/iOmmlatrmLk"
        ></iframe>
      </S.ModalYoutube>
    </S.Wrapper>
  );
}

export default CollectionDetail;
