import styled from 'styled-components';

import { Modal as BaseModal } from 'antd';
import media from '../../../styles/breakpoints';

export const Detail = styled(BaseModal)`
  width: 100%;
  position: relative;

  .ant-modal-content {
    width: 580px;
  }

  .ant-modal-body {
    padding: 32px;
  }

  ${media.phone`{
    .ant-modal-content {
      width: 100%;
    }  

 `}
`;

export const Edit = styled.div`
  position: absolute;
  text-align: right;
  padding-right: 24px;
  right: 54px;
  top: 18px;
  border-right: 1px solid #e7e7e7;
`;
