import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { ROUTES } from '../../routes/constants';
import Button from '../../components/commons/Button';

import logo from '../../assets/icons/logo.svg';
import buffy from './images/buffy.png';

import * as S from './styled';

function Sale() {
  const history = useHistory();

  return (
    <S.Wrapper
      style={{
        background: 'linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <S.WrapLogo>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.WrapContent>
        <S.Top>
          <div className="title">SALES OVER</div>
          <div className="sub-title">
            The current sale is over, please come back later or contact us via
          </div>
          <div className="email">
            EMAIL:
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=SOCIAL@DINOVERSE.IO"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              <span>SOCIAL@DINOVERSE.IO</span>
            </a>
          </div>

          <div className="button">
            <Button
              label="BACK TO HOMEPAGE"
              onClick={() => history.push(ROUTES.HOME)}
              className="button-back"
            />
          </div>
        </S.Top>
        <S.Bottom>
          <div className="buffy">
            <img src={buffy} alt="" />
          </div>
          <div className="policy">
            Policy ID: 926075ba72037da019e28565bd379662202132723dc10bd13ffc6850
          </div>
          <div className="copyright">Copyright © 2022 Dinoverse. All rights reserved</div>
        </S.Bottom>
      </S.WrapContent>
    </S.Wrapper>
  );
}

export default Sale;
