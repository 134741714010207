import logo from '../../assets/icons/logo.svg';
import Tyranno from './images/tyranno.png';
import Raptor from './images/raptor.png';
import Triceratops from './images/coming-soon1.png';
import Pterodactyl1 from './images/coming-soon2.png';
import Pterodactyl2 from './images/coming-soon3.png';
import CardFrame from './images/CardFrame.png';
import Baby from './images/baby.png';
import Egg from './images/egg.png';

export default {
  logo,
  Tyranno,
  Raptor,
  Triceratops,
  Pterodactyl1,
  Pterodactyl2,
  CardFrame,
  Baby,
  Egg,
};
