/* IMAGE */

import logo from '../../assets/image/png/logo.png';
import map1 from '../../assets/image/png/map1.png';
import map2 from '../../assets/image/png/map2.png';
import earth from '../../assets/image/png/earth.png';
import trexGif from '../../assets/image/gif/TRex.gif';
import cloud from '../../assets/image/png/cloud.png';
import backgroundCloud from '../../assets/image/png/background-cloud.png';


/* ICON */ 

import iconTRex from '../../assets/image/svg/TRexFoot.svg';
import twitter from '../../assets/image/svg/twitter.svg';
import discord from '../../assets/image/svg/discord.svg';


export default{
    logo,
    iconTRex,
    twitter,
    discord,
    map1,
    map2,
    earth,
    trexGif,
    cloud,
    backgroundCloud
}