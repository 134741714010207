import styled, { css } from 'styled-components';
import { Tabs } from 'antd';

import media from '../../styles/breakpoints';

export const WrapContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const WrapTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  text-align: center;
  padding-top: 30px;
  max-width: 100%;

  .ant-tabs.active-project-overview .ant-tabs-nav-wrap,
  .ant-tabs.active-our-team .ant-tabs-nav-wrap {
    justify-content: center;
  }

  .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
    transition: all 0.5s;
    transform: translateX(120px);
  }

  .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
    transition: all 0.5s;
    transform: translateX(-240px);
  }

  .ant-tabs-content-holder {
    max-width: 50%;
    margin: auto;
  }

  ${media.largeDesktop`{
    .ant-tabs-content-holder {
      max-width: 60%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(185px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-340px);
    }
  }`}

  ${media.fullHDDesktop`{
    .ant-tabs-content-holder {
      max-width: 60%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(120px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-240px);
    }
  }`}

  ${media.desktop`{
    .ant-tabs-content-holder {
      max-width: 60%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(120px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-240px);
    }
  }`}

  ${media.hdDesktop`{
    .ant-tabs-content-holder {
      max-width: 70%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(100px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-180px);
    }
  }`}

  ${media.smallDesktop`{
    .ant-tabs-content-holder {
      max-width: 70%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(100px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-180px);
    }
  }`}
  
  ${media.lTablet`{
    .ant-tabs-content-holder {
      max-width: 85%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(100px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-180px);
    }
  }`}

  ${media.glxFoldBig`{
    .ant-tabs-content-holder {
      max-width: 85%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(80px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-160px);
    }
  }`}

  ${media.phone`{
    padding-top: 75px;
    .ant-tabs-content-holder {
      max-width: 85%;
    }
    .ant-tabs.active-project-overview .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(60px);
    }
  
    .ant-tabs.active-our-team .ant-tabs-nav-list .ant-tabs-tab {
      transition: all 0.5s;
      transform: translateX(-120px);
    }
  }`}

  ${media.sPhone`{
    .ant-tabs-content-holder {
      max-width: 90%;
    }
  }`}

  ${media.xsPhone`{
    .ant-tabs-content-holder {
      max-width: 90%;
    }
  }`}
`;

export const Tab = styled(Tabs)`
  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav {
    margin-bottom: 72px;
    margin-top: 50px;
  }

  .ant-tabs-nav:before {
    width: 79px;
    border: 1px solid #4f9f14;
    margin: 0 auto;
  }

  .ant-tabs-tab {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 58px;
    background: #9fb7d5;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // opacity: 20%;
  }

  .ant-tabs-tab ant-tabs-tab-active {
    opacity: 100%;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    background: -webkit-linear-gradient(#516462, #23312f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    opacity: 0.2;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    opacity: 1;
  }

  .content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #2a3635;
  }

  .wrap-content {
    display: flex;
    gap: 45px;
    flex-direction: column;
  }

  .content > p {
    padding: 0 100px;
  }

  .button {
    margin: 0 auto;
  }

  .child-button {
    padding: 0;
  }

  .learn-more {
    margin-top: 15px;
  }

  .card-desktop {
    display: flex;
    justify-content: space-between;
  }

  .card-mobile {
    display: none;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li.slick-active button:before {
    color: #15a06e;
  }

  ${media.largeDesktop`

    .content{
      font-size: 25px;
      line-height: 30px;
    }

    .ant-tabs-tab-btn{
      font-size: 60px;
      line-height: 70px;
    }

    .ant-tabs-nav:before{
      width: 100px;
    }
  `}

  ${media.fullHDDesktop`
    .content{
      font-size: 16px;
      line-height: 30px;
    }

    .ant-tabs-tab-btn{
      font-size: 40px;
      line-height: 58px;
    }

    .ant-tabs-nav:before{
      width: 100px;
    }  `}

  ${media.desktop`
  .content{
    font-size: 16px;
    line-height: 30px;
  }

  .ant-tabs-tab-btn{
    font-size: 40px;
    line-height: 58px;
  }

  .ant-tabs-nav:before{
    width: 100px;
  }  `}

  ${media.hdDesktop`
  .content{
    font-size: 13px;
    line-height: 30px;
    position: relative;
    top: -20px;
  }

  .ant-tabs-tab-btn{
    font-size: 30px;
    line-height: 48px;
  }

  .ant-tabs-nav:before{
    width: 70px;
  }
  .wrap-content{
    gap:0;

  }

  .content > p {
    padding: 0 60px;
  }
  `}

  ${media.smallDesktop`
  .content{
    font-size: 11px;
    line-height: 30px;
    position: relative;
    // top: -30px;
  }

  .ant-tabs-tab-btn{
    font-size: 30px;
    line-height: 48px;
  }

  .ant-tabs-nav:before{
    width: 70px;
  }
  .wrap-content{
    gap:0;

  }

  .content > p {
    padding: 0 30px;
  }
  `}

  ${media.glxFoldBig`

  .card-desktop{
    display: none;
  }

  .card-mobile{
    display: block;
  }

  .ant-tabs-nav {
    margin-top: 20px;
    margin-bottom: 30px;
  }  


  .content{
    font-size: 10px;
  }

  .content > p{
    line-height: 15px;
  }

  .ant-tabs-tab-btn{
    font-size: 25px;
    line-height: 35px;
  }

  .child-button {
    width: 120px;
    height: 25px;
    font-size: 10px;
    }
  `}

  ${media.phone`

    .ant-tabs-nav {
      margin-top: 10px;
      margin-bottom: 70px;
    }

    .content{
      position: relative;
      top: -47px;
    }
    .ant-tabs-tab-btn{
      font-size: 18px;
      line-height: 28px;
    }

    .child-button {
      width: 193px;
      height: 35px;
      font-size: 14px;
    }

    .content > p{
      line-height: 25px;
    }

    .card-desktop{
      display: none;
    }

    .card-mobile{
      display: block;
    }
  `}

  ${media.glxFold`

  .content{
    font-size: 10px;
  }

  .content > p{
    line-height: 20px;
  }

  .ant-tabs-tab-btn{
    font-size: 13px;
    line-height: 25px;
  }

  .child-button {
    width: 120px;
    height: 25px;
    font-size: 10px;
    }
  `}
`;

export const WrapTrex = styled.div`
  & > img {
    max-width: 15%;
    position: absolute;
  }

  .left {
    bottom: 2%;
    left: 5%;
    transform: scaleX(-1);
  }

  .right {
    bottom: 15%;
    right: 1%;
  }

  ${media.smallDesktop`

    .left{
      bottom: 7%;
    }

    .right{
      bottom: 3%;
    }
  `}

  ${media.lTablet`

  & > img{
    max-width: 30%;

  }
    .left{
      bottom: 9%;

    }

    .right{
      bottom: 5%;

    }
  `}

  ${media.glxFoldBig`

  & > img{
    max-width: 20%;
  }

  .left{
    bottom: 5%;
  }

  .right{
    bottom: 0%;
  }
  `}

  ${media.phone`
  & > img{
    max-width: 30%;
  }

  .left{
    bottom: 7%;
  }

  .right{
    bottom: 5%;
  }

  `}



  ${media.glxFold`

  & > img{
    max-width: 35%;
  }

  .left{
    bottom: 2%;
  }

  .right{
    bottom: 2%;
  }
  `}
`;

export const WrapCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .position {
    display: flex;
    flex-direction: column;
  }

  .link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    color: #15a06e;
  }

  .card-info > img {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .card-info-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ${media.largeDesktop`
  .card-info > img{
    max-width: 100%;

    }
    .link{
      font-size:16px;
    }
  `}

  ${media.fullHDDesktop`
  .card-info > img{
    max-width: 100%;

    }
    .link{
      font-size:14px;
    }
  `}

  ${media.desktop`
  .card-info > img{
    max-width: 100%;

  }
  .link{
    font-size:12px;
  }
`}

  ${media.hdDesktop`
  .card-info > img{
    max-width: 80%;
  }
    .link{
      font-size:11px;
    }
  `}

  ${media.smallDesktop`
  .card-info > img{
    max-width: 80%;
  }
  .link{
    font-size:9px;
  }
  `}
`;

export const WrapModal = styled.div`
  display: flex;
  gap: 30px;
`;

export const LeftModal = styled.div`
  flex-basis: 40%
  text-align: center;
`;

export const RightModal = styled.div`
  flex-basis: 60%;
  display: flex;
  align-items; center;
  flex-direction: column;
  justify-content: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;

  .name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #222f2d;
  }

  .position {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 16px;
    text-transform: uppercase;
    color: #222f2d;
  }

  .nationality {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    color: #222f2d;
  }

  .fav-dino {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    color: #222f2d;
  }

  ${media.largeDesktop`

    margin-top: 20px;

    .name{
      font-size:14px;
    }

    .position{
      font-size:10px;
      line-height:20px;
    }

    .nationality{
      font-size:13px;
    }
    .fav-dino{
      font-size:13px;
    }
  `}

  ${media.fullHDDesktop`
    .name{
      font-size:14px;
    }

    .position{
      font-size:10px;
    }

    .nationality{
      font-size:12px;
    }  

    .fav-dino{
      font-size:13px;
    }
    `}

  ${media.desktop`
  .name{
    font-size:13px;
  }

  .position{
    font-size:10px;
    line-height:15px;

  }

  .nationality{
    font-size:12px;
  }  

  .fav-dino{
    font-size:13px;
  }
  `}

  ${media.hdDesktop`
  .name{
    font-size:12px;
  }

  .position{
    font-size:9px;
    line-height:15px;

  }

  .nationality{
    font-size:11px;
  }  

  .fav-dino{
    font-size:13px;
  }

  & > img{
    max-width: 80%;
  }
  `}

  ${media.smallDesktop`
  .name{
    font-size:10px;
  }

  .position{
    font-size:7px;
    line-height:10px;
  }

  .nationality{
    font-size:9px;
  } 

  .fav-dino{
    font-size:10px;
  }

  `}

  ${media.phone`{
    margin-top: 10px;
    gap: 2px;
  }`}
`;

export const InfoReadMore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
  text-align: center;

  .name {
    margin-top: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #222f2d;
  }

  .position {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 16px;
    text-transform: uppercase;
    color: #222f2d;
  }

  .nationality {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    color: #222f2d;
  }

  .line {
    margin: 0 auto;
    width: 13px;
    height: 2px;
  }

  .icon {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
`;

export const InforDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;

  .text-header {
    color: #5a9b93;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
  }

  .fav-dino {
    font-family: Montserrat;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2a3635;
  }

  .fav-dino & span {
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #2a3635;
  }

  .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #2a3635;
  }

  ${media.phone`{
    .ant-modal-content {
      width: 100%;
    }  

    .text-header{
      font-size: 10px;
    }

    .fav-dino{
      font-size: 10px;
    }

    .description{
      font-size: 10px
    }
  }`}
`;

export const WrapSlider = styled.div``;
