import styled from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: scroll;

  ${media.lTablet`
  overflow: hidden;

  `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const WrapContent = styled.div`
  top: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 100px 20px 27px 20px;

  ${media.largeDesktop`
    padding-top: 210px;
  `}

  ${media.fullHDDesktop`
    padding-top: 100px;
  `}

  ${media.desktop`
    padding-top: 80px;
  `}

  ${media.hdDesktop`
    padding-top: 40px;
  `}

  ${media.smallDesktop`
    padding-top: 60px;
  `}

  ${media.lTablet`
    padding-top: 250px;
  `}

  ${media.tablet`
    padding-top: 150px;
  `}

  ${media.glxFoldBig`
    padding-top: 55px;
  `}

  ${media.phone`
    padding-top: 100px;
  `}

  ${media.xsPhone`
    padding: 70px 10px 20px 10px;
  `}

  ${media.glxFold`
    padding-top: 70px;
  `}
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    letter-spacing: 0.2rem;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-size: 72px;
    line-height: 58px;
    background: -webkit-linear-gradient(270deg, #f9931a 5.46%, #d35610 96.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 48px;
    text-align: center;
  }

  .sub-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
    margin-top: 32px;
    padding: 0px 300px;
  }

  .email {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: #425452;
    margin-bottom: 30px;
    text-align: center;
  }

  .button {
    display: flex;
    gap: 30px;
    margin-bottom: 40px;
  }

  ${media.fullHDDesktop`
    .email {
      margin-bottom: 0px;
    }
  `}

  ${media.desktop`
    .title {
      margin-bottom: 24px;
      font-size: 66px;
    }

    .video-youtube {
      width: 444px;
      height: 250px;
    }

    .sub-title {
      font-size: 16px;
      line-height: 18px;
      margin: 16px 0px;
    }

    .button {
      margin-top: 16px;
    }
  `}

  ${media.hdDesktop`
    .title{
      font-size: 60px;
      margin-bottom: 16px;
    }

    .video-youtube {
      width: 890px !important;
      height: 500px !important;
    }

    .sub-title{
      font-size: 16px;
      margin-bottom: 8px;
    }

    .email{
      font-size: 14px;
      margin-bottom: 15px;
    }

    .button-back{
      width: 204px;
      height: 36px;
      font-size: 13px
    }

  `}

  ${media.smallDesktop`

  .video-youtube {
      width: 900px !important;
      height: 507px !important;
    }
    .sub-title {
      padding: 0 200px;
    }
  `}

  ${media.lTablet`

  .video-youtube {
    width: 700px !important;
    height: 394px !important;
  }

    .title {
      font-size: 72px;
      margin-bottom: 48px;
    }

    .sub-title {
      font-size: 20px;
      margin-bottom: 16px;
      padding: 0px 20px;
    }

    .email {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .button-back {
      width: 265px;
      height: 47px;
      font-size: 16px
    }
  `}

  ${media.glxFoldBig`
    .title {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 8px;
    }

    .sub-title {
      font-size: 10px;
      line-height: 16px;
    }

    .email {
      font-size: 10px;
      margin-bottom: 8px;
    }

    .video-youtube{
      width: 299px !important;
    height: 168px !important;
    }

    .button {
      margin-top: 8px;
    }

    .button-back {
      width: 161px;
      height: 30px;
      font-size: 9px;
    }
  `}

  ${media.phone`
  .video-youtube{
    width: 332px !important;
    height: 187px !important;
  }

    .title {
      margin-bottom: 32px;
    }

    .sub-title {
      padding: 0 16px;
    }
  `}

  ${media.xsPhone`
    .sub-title {
      padding: 0px;
    }
  `}

  ${media.glxFold`
    .title {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .sub-title {
      margin: 8px 0px;
      line-height: 14px;
    }

    .button {
      margin-top: 0px;
    }

    .button-back{
      width: 117px;
      height: 30px;
      font-size: 6px;
    }

    .video-youtube {
      width: 259px !important;
      height: 146px !important;
    }
  `}
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .buffy {
    margin-bottom: 23px;
    object-fit: none;
  }

  .policy {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #425452;
    margin-bottom: 12px;
    text-align: center;
    word-wrap: break-word;
    width: 100%;
    gap: 4px;
  }

  .copyright {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #425452;
    text-align: center;
  }

  ${media.desktop`
    .buffy {
      margin-bottom: 16px;
    }
  `}

  ${media.hdDesktop`
    .buffy {
      display:flex;
      justify-content: center;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .buffy > img{
      max-width: 65%;
    }
  `}

  ${media.glxFoldBig`
    .buffy {
      margin: 16px 0 8px 0;
    }
  `}

  ${media.phone`
    .policy{
      font-size: 11px;
    }
  `}

  ${media.glxFold`
    .copyright {
      font-size: 8px;
    }
  `}
`;
