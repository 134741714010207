import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import ModelPangea from './ModelPangea';
import Loader from '../Loader';

export default function ScenePangea({ ...rest }) {
  return (
    <Canvas {...rest}>
      <Suspense fallback={<Loader />}>
        <ModelPangea
          position={[1, -4.5, 1.8]}
          scale={1.1}
          rotation={[(8 * Math.PI) / 180, (60 * Math.PI) / 180, (12.5 * Math.PI) / 180]}
        />
      </Suspense>
    </Canvas>
  );
}
