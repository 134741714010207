/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

import logo from '../../assets/icons/logo.svg';
import placeholder from './images/placeholder.png';

import * as S from './styled';

const mediumUrl =
  'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40dinoverse';

/* Link to Twitter API + Bearer Token

const twitterUrl = 'https://api.twitter.com/2/users/1434130646851485709/tweets?max_results=100&tweet.fields=public_metrics,created_at&exclude=retweets,replies';

const token = 'AAAAAAAAAAAAAAAAAAAAAAn0ZwEAAAAA4CF3sow6HNCCyB2OV5UxhC8Xrjw%3DbgW1E6h59WvJz4DAW5Z4LkEtiSVaHessYgQq8mk2hxvgFU0Phh';

*/

const Loading = () => (
  <div className="post loading">
    <h5>Loading...</h5>
  </div>
);

const LongText = ({ content, limit }) => {
  if (content.length <= limit) {
    return <div>{content}</div>;
  }
  const toShow = `${content.substring(0, limit)}...`;
  return <div>{toShow}</div>;
};

function Media() {
  const [medium_data, set_medium_data] = useState(null);

  /* Config + Call Twitter API 

  const config = {
    headers: {
      'authorization': `Bearer ${token}`, 
    }
  }

  useEffect(() => {
    axios.get(twitterUrl, config).then((res) => console.log('data', res.data)).catch((err) => console.log('errors', err.toJSON()));
  });
  */

  useEffect(() => {
    axios
      .get(mediumUrl)
      .then((res) => {
        set_medium_data(res.data.items);
      })
      .catch((err) => console.log('MEDIUM ERROR', err));
  }, []);

  console.log(medium_data);

  return (
    <S.Wrapper
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <S.WrapLogo>
        <img src={logo} alt="" />
      </S.WrapLogo>

      <S.WrapHeader>
        <p className="header">SOCIAL MEDIA & BLOG</p>
        <p className="sub-header">Don't miss the latest news</p>
        <hr className="line"></hr>
      </S.WrapHeader>
      <S.WrapTabs>
        <div className="tabs">
          <S.Tab animated={{ inkBar: false, tabPane: true }} centered defaultActiveKey="1">
            <S.Tab.TabPane tab="TWITTER NEWS FEED" key="1" className="content"></S.Tab.TabPane>
            <S.Tab.TabPane tab="MEDIUM NEW POST" key="2" className="content">
              {medium_data?.map((item, index) => (
                <LazyLoad key={index} height={100} offset={[-100, 100]} placeholder={<Loading />}>
                  <S.WrapContent key={index}>
                    <div className="wrap-content">
                      <div className="thumnails">
                        <img src={item?.thumbnail || placeholder} alt=""></img>
                      </div>
                      <div className="content">
                        <div className="title">
                          <LongText content={item?.title} limit={25} />
                        </div>
                        <div className="description">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.description
                                .replace(/<img[^>]*>/g, '')
                                .slice(0, 160)
                                .concat('...'),
                            }}
                          ></div>
                        </div>
                        <a href={item?.link} target="_blank" rel="noopener noreferrer">
                          <div className="link">medium.com</div>
                        </a>
                        <a href={item?.link} target="_blank" rel="noopener noreferrer">
                          <div className="readmore">READ MORE</div>
                        </a>
                      </div>
                    </div>
                  </S.WrapContent>
                </LazyLoad>
              ))}
            </S.Tab.TabPane>
          </S.Tab>
        </div>
      </S.WrapTabs>
    </S.Wrapper>
  );
}

export default Media;
