import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { AmbientLight } from 'three';
import { OrbitControls } from '@react-three/drei';

import ModelHomePage from './ModelHomePage';
import Loader from '../Loader';

// Lights
function KeyLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, 0, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}
function FillLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      intensity={brightness}
      color={color}
      position={[2, 1, 4]}
      lookAt={[0, 0, 0]}
      penumbra={2}
      castShadow
    />
  );
}

function RimLight({ brightness, color, position }) {
  return (
    <rectAreaLight
      width={2}
      height={2}
      intensity={brightness}
      color={color}
      position={position}
      rotation={[0, 180, 0]}
      castShadow
    />
  );
}

export default function SceneHomePage({ ...rest }) {
  return (
    <Canvas {...rest}>
      <OrbitControls enableZoom={false} enablePan={false} />
      <KeyLight brightness={5.6} color="#ffc9f9" />
      <FillLight brightness={5.6} color="#bdefff" />
      <RimLight brightness={24} position={[1, 2, -4]} color="#bdefff" />
      <RimLight brightness={24} position={[-1, 2, -4]} color="#bdefff" />
      <Suspense fallback={<Loader />}>
        <ModelHomePage position={[0, 0, 0]} scale={1.7} rotation={[0, (69 * Math.PI) / 180, 0]} />
      </Suspense>
    </Canvas>
  );
}
