import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { ROUTES } from '../../routes/constants';

import bg from './images/bg.jpg';
import logo from '../../assets/icons/logo.svg';
import facebook from './images/svg/facebook.svg';
import instagram from './images/svg/instagram.svg';
import linkedin from './images/svg/linkedin.svg';
import twitter from './images/svg/twitter.svg';
import Button from '../../components/commons/Button';
import Detail from '../../components/commons/ModalDetail';
import trex1 from './images/gif/trex1.gif';
import trex2 from './images/gif/trex2.gif';
import amelia from './images/amelia.png';
import bill from './images/bill.png';
import jeff from './images/jeff.png';
import nat from './images/nat.png';
import joseph from './images/joseph.png';

import * as S from './styled';

function About() {
  const defaultTab = useSelector((state) => state.authReducer?.tab);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [readMore, setReadMore] = useState({});
  const [tab, set_tab] = useState('1');

  if (isModalVisible) {
    window.fullpage_api?.setAllowScrolling(false);
  } else {
    window.fullpage_api?.setAllowScrolling(true);
  }

  useEffect(() => {
    set_tab(defaultTab);
  }, [defaultTab]);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
  };

  const showModal = (item) => {
    setIsModalVisible(true);
    setReadMore(item);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const arrTeam = [
    {
      id: 1,
      image: jeff,
      name: 'Jeff / Raginzephyr',
      pos: ['Co-Founder', 'Project Manager'],
      nationality: 'Australia',
      favDino: 'T-Rex',
      description:
        'Jeff is our visual creative and 3D modeling expert. He has more than 15 years of experience in 3D animation, product development, and application development across a variety of platforms including Blockchain. He is found in Dinoverse a passionate project where he can combine his technical expertise and childhood love of dinosaurs. ',
    },
    {
      id: 2,
      image: joseph,
      name: 'Joseph / JVP',
      pos: ['Co-Founder', 'Business Development'],
      nationality: 'Australia',
      favDino: 'Pteranodon',
      description:
        'Joseph is an experienced IT professional with a range of technical skills and experiences, including blockchain. Joseph is excited about demonstrating the potential of the Cardano blockchain and founded Dinoverse with Jeffrey to realize their shared passion and interests in dinosaurs.  ',
    },
    {
      id: 3,
      image: bill,
      name: 'Bill Nguyen',
      pos: ['3D Assets modeling', 'Texturing', 'Team Supervision'],
      nationality: 'Vietnam',
      favDino: 'Spinosaurus',
      description:
        'Bill is a paleontology-enthusiast, self-taught modeler and a 3D artist with over 6 years of professional experience. His fascination for dinosaurs started when he was 10 years old when he saw them on TV and is bringing them back to life now in the 3D Dinoverse metaverse. ',
    },
    {
      id: 4,
      image: nat,
      name: 'Shyrajha / Nat',
      pos: ['Concept Art', 'Texturing Graphic Design', 'Community Management'],
      nationality: 'Germany',
      favDino: 'Microraptor',
      description:
        'Nat is a passionate creature-designer with over 9 years of experience. She has worked an artist for Path of Titans and other Indie Games. She currently is working on Dinoverse dino skin designs and visuals. Nat is a firm believer that anyone can do anything as long as they are passionate, eager and open to learn and try new things! ',
    },
    {
      id: 5,
      image: amelia,
      name: 'Amelia / Ailema15',
      pos: ['Community Management'],
      nationality: 'Guatemala',
      favDino: 'Triceratops',
      description:
        'Amelia has over 11 years digital marketing experience, and has worked in the European startup space for 5 years. She is obsessed with dinos and is applying her skills and experience to helping raise awareness and engagement with the Dinoverse project!  ',
    },
  ];

  return (
    <S.WrapContent
      style={{
        backgroundImage: `linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%), url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
      }}
    >
      <S.WrapLogo onClick={() => window.fullpage_api?.moveTo(1)} style={{ cursor: 'pointer' }}>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.WrapTabs>
        {/* <div className="tabs"> */}
        <S.Tab
          animated={{ inkBar: false, tabPane: true }}
          centered
          activeKey={tab}
          onChange={(key) => set_tab(key)}
          className={tab === '1' ? 'active-project-overview' : 'active-our-team'}
        >
          <S.Tab.TabPane
            tab="PROJECT OVERVIEW"
            key="1"
            className="content"
            style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
          >
            <p style={{ fontWeight: '700' }}>
              Dinoverse is a Cardano-based 3D model NFT that is bringing dynamic 3D animated
              dinosaurs and other mythical creatures to life in the Pangea metaverse.
            </p>
            <p>
              We are excited to bring rich, immersive and interactive 3D assets on the Cardano
              blockchain, with virtual land inspired by the ancient Pangea supercontinent.
            </p>
            <p className="learn-more">Learn more about our vision.</p>
            <a className="button" href={ROUTES.LITE} target="_blank" rel="noreferrer">
              <Button className="child-button" label="LITE PAPER" />
            </a>
          </S.Tab.TabPane>
          <S.Tab.TabPane tab="OUR TEAM" key="2" className="content">
            <div className="wrap-content">
              <p>
                <span style={{ fontWeight: '700' }}>
                  We are a dinosaur loving global team based across Australia, Asia, Europe, Central
                  and North America.
                </span>{' '}
                Our team comprises 3D artists, 3D modelers, 3D riggers, 3D animators, concept
                artists, web and blockchain developers and a community moderator team.
              </p>
              <div className="card-desktop">
                {arrTeam.map((item) => (
                  <S.WrapCard>
                    <div className="card-info">
                      <img src={item.image} alt="" />
                      <S.Info>
                        <div className="name">
                          <div>{item.name}</div>
                        </div>
                        <div className="position">
                          {item.pos.map((item) => (
                            <div>{item}</div>
                          ))}
                        </div>
                        <div className="nationality">{item.nationality}</div>
                        <div className="fav-dino">{item.favDino}</div>
                      </S.Info>
                      <a
                        className="link"
                        aria-hidden="true"
                        onClick={() => showModal(item)}
                        onKeyDown={() => showModal(item)}
                      >
                        READ MORE
                      </a>
                    </div>
                  </S.WrapCard>
                ))}
              </div>

              <div className="card-mobile">
                <Slider {...settings}>
                  {arrTeam.map((item) => (
                    <S.WrapCard>
                      <div className="card-info-mobile">
                        <img style={{ maxWidth: '40%' }} src={item.image} alt="" />
                        <S.Info>
                          <div className="name">
                            <div>{item.name}</div>
                          </div>
                          <div className="position">
                            {item.pos.map((item) => (
                              <div>{item}</div>
                            ))}
                          </div>
                          <div className="nationality">{item.nationality}</div>
                          <div className="fav-dino">{item.favDino}</div>
                        </S.Info>
                        <a
                          className="link"
                          aria-hidden="true"
                          onClick={() => showModal(item)}
                          onKeyDown={() => showModal(item)}
                        >
                          READ MORE
                        </a>
                      </div>
                    </S.WrapCard>
                  ))}
                </Slider>
              </div>
            </div>
            <Detail visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
              <S.WrapModal>
                <S.LeftModal>
                  <img src={readMore.image} alt="" />
                  <S.InfoReadMore>
                    <div className="name">
                      <div>{readMore.name}</div>
                    </div>
                    <div className="position">
                      {readMore?.pos?.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                    <div className="nationality">{readMore.nationality}</div>
                    <hr className="line"></hr>
                    <div className="icon">
                      <img src={facebook} alt="" />
                      <img src={twitter} alt="" />
                      <img src={instagram} alt="" />
                      <img src={linkedin} alt="" />
                    </div>
                  </S.InfoReadMore>
                </S.LeftModal>
                <S.RightModal>
                  <S.InforDetail>
                    <div className="fav-dino">
                      Fav dino: <span style={{ fontWeight: '700' }}>{readMore.favDino}</span>
                    </div>
                    <div className="description">{readMore.description}</div>
                  </S.InforDetail>
                </S.RightModal>
              </S.WrapModal>
            </Detail>
          </S.Tab.TabPane>
        </S.Tab>
        {/* </div> */}
      </S.WrapTabs>

      <S.WrapTrex>
        <img src={trex1} alt="" className="left" />
        <img src={trex2} alt="" className="right" />
      </S.WrapTrex>
    </S.WrapContent>
  );
}

export default About;
