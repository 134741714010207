import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';

NonAuthRoute.propTypes = {
  component: PropTypes.any,
};

function NonAuthRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest}>
      <Layout>
        <Component />
      </Layout>
    </Route>
  );
}

export default NonAuthRoute;
