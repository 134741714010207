import styled from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  font-family: Montserrat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  ${media.phone`
    padding: 0 10px;
  `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const MainFrame = styled.div`
  width: 501px;
  height: 547px;
  position: relative;
  margin-bottom: 30px;
  ${media.tablet`
    width: 400px;
    height: 446px;
    margin-bottom: 24px;
  `}
  ${media.phone`
    width: 300px;
    height: 326px;
    margin-bottom: 20px;
  `}
`;

export const Ellipse = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    max-width: unset;
    ${media.tablet`
      max-width: 350px;
    `}
    ${media.phone`
      max-width: 250px;
    `}
  }
`;

export const DinoRun = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  img {
    max-width: unset;
    ${media.tablet`
      max-width: 350px;
    `}
    ${media.phone`
      max-width: 250px;
    `}
  }
`;

export const ComingText = styled.h1`
  position: absolute;
  width: fit-content;
  font-size: 48px;
  font-weight: 900;
  line-height: 58px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  background: -webkit-linear-gradient(255deg, #5cae18, #21b0c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.tablet`
      line-height: 36px;
      font-size: 36px;
    `}
  ${media.phone`
      line-height: 28px;
      font-size: 25px;
    `}
`;

export const Desc = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #222f2d;
  margin-bottom: 16px;
  text-align: center;
  ${media.tablet`
      line-height: 16px;
      font-size: 18px;
    `}
  ${media.phone`
      font-size: 11px;
    `}
`;

export const EmailText = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  span {
    margin-right: 8px;
  }
  strong {
    color: #06a79e;
  }
  ${media.tablet`
      line-height: 19px;
      font-size: 14px;
    `}
  ${media.phone`
      line-height: 15px;
      font-size: 12px;
    `}
`;
