import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { section } from '../../store/authSlice/index';

import { ROUTES } from '../../routes/constants';

import background from '../CollectionDetail/images/bg.png';
import logo from '../../assets/icons/logo.svg';
import run from './images/run.png';
import Button from '../../components/commons/Button';
import Ellipse from './images/ellipse.png';

import * as S from './styled';

function ComingSoon() {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <S.Wrapper backgroundImage={background}>
      <S.WrapLogo>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.MainFrame>
        <S.Ellipse>
          <img src={Ellipse} alt="" />
        </S.Ellipse>
        <S.DinoRun>
          <img src={run} alt="" />
          <img src={logo} alt="" style={{ width: '100%' }} />
        </S.DinoRun>
        <S.ComingText>Coming Soon</S.ComingText>
      </S.MainFrame>
      <S.Desc>This page is being updated, please come back later or contact us via</S.Desc>
      <S.EmailText>
        <span>Email:</span>
        <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=SOCIAL@DINOVERSE.IO">
          <strong>social@dinoverse.io</strong>
        </a>{' '}
      </S.EmailText>
      <Button
        label="BACK TO HOMEPAGE"
        onClick={() => {
          dispatch(section(5));
          history.push(ROUTES.HOME);
        }}
      ></Button>
    </S.Wrapper>
  );
}

export default ComingSoon;
