import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../routes/constants';

import * as S from './styled';

import logo from '../../assets/icons/logo.svg';
import backgroundCloud from '../../assets/image/png/background-cloud.png';
import backgroundReveal from './images/reveal-bg.gif';

function Story() {
  const reveal = useRef();
  const history = useHistory();

  const handleMouseMove = (e) => {
    reveal.current.style.left = `${e.pageX - reveal.current.offsetWidth / 2}px`;
    reveal.current.style.top = `${e.pageY - reveal.current.offsetWidth / 2}px`;
  };
  return (
    <>
      <S.Wrapper
        id="wrapper"
        onMouseMove={handleMouseMove}
        style={{
          backgroundImage: `url(${backgroundCloud}), linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: '#fff',
        }}
      >
        <S.RevealArea>
          <S.RevealBackground
            id="reveal"
            backgroundImage={backgroundReveal}
            ref={reveal}
          ></S.RevealBackground>
        </S.RevealArea>
        <S.WrapLogo onClick={() => history.push(ROUTES.HOME)} style={{ cursor: 'pointer' }}>
          <img src={logo} alt="" />
        </S.WrapLogo>
        <S.Heading>
          “God Creates Dinosaurs. God Destroys Dinosaurs. God Creates Man. Man Destroys God. Man
          Creates Dinosaurs.”
        </S.Heading>
        <S.SubHeading>- Dr. Ian Malcolm’s book, God Creates Dinosaurs -</S.SubHeading>
        <S.Title>THE STORY SO FAR</S.Title>
        <S.WrapDescription>
          <S.Description>
            At first there was nothing. A vast, endless nothingness that engulfed the universes with
            a darkness so thick and never ending, it swallowed any starlight within it. Like a
            supernova made out of darkness.
          </S.Description>
          <S.Description>
            Nothingness ruled the universe for centuries until time and shapes began to shift within
            the darkness. Stars began to collide and formed together, moulded by heat and fire as it
            spread throughout the Metaverse. A new era had begun and life was on its way to rise.
          </S.Description>
          <S.Description>
            Within the stars, new planets became inhabitable and not even another century was needed
            until the planet blossomed with life. It would be called ‘The Dinoverse’.
          </S.Description>
          <S.Description>
            The Dinoverse offered the needed resources and needs to inhabit all kinds of different
            dinosaur species throughout its lands. From the dry and hot deserts to the wet and deep
            underwater caves over the range of snowy mountains and deep jungles, life was
            flourishing.
          </S.Description>
          <S.Description>
            But the mightiest of them all was the Tyrannosaurus Rex. A large, bipedal carnivorous
            species of dinosaurs that was stomping through the Dinoverse with its heavy footsteps.
            Anything feared it and so it quickly became the top predator in the food chain. Nothing
            could take it down or even stand a chance against it. At least not on their own.
          </S.Description>
          <S.Description>
            But life adapted very quickly and created new species that used their brain against the
            brawn. Raptors may lack significantly in size towards a huge, meat-eating Tyrannosaur
            but with their agility, capability to adapt and their own kind to form packs for
            coordinated attacks, it wouldn’t take long for those little speeding devils to establish
            a high spot in the food chain themselves. With this new milestone of evolution even the
            mightiest and most battle proven Tyrannosaur had to keep an eye out from now on. Because
            small could also mean deadly now.
          </S.Description>
          <S.Description>
            As time continued and territorial fights were fought over and over again as a form of
            never ending life cycles to feed, survive and fight, the dinosaurs began to feel uneasy.
            A mysterious, unknown shadow was creeping around them.
          </S.Description>
          <S.Description>
            Triceratops males that had just stopped for a new nesting spot to start and fight for
            their harems of females noticed it first. A darker shade of blue and black that had
            followed them through their long marsh over the icy and frozen lake. The entity wasn’t
            hunting them, wasn’t doing anything but following. Almost as if it was watching them.
          </S.Description>
          <S.Description>
            Even the Stegosaurus that were strolling through the river began to notice as the very
            same shadow made its way through the river. Wriggling and shape shifting like smoke
            beneath their feet. Hatchlings began to panic call and hid underneath their parents.
            Freshly hatched, the sight of such darkness felt wrong and cold.
          </S.Description>
          <S.Description>
            But that was nothing against the sightings a big flock of Pterodactyl had made the next
            day near the shoreline to hunt for fish and hopefully some dead skin from the towering
            Brontosauruses making their long and slow way into the east. The shadow was larger than
            any other times, creeping alongside the cliffs and covering most of their nests in
            darkness before it eventually moved on.
          </S.Description>
          <S.Description>
            Nothing was harmed, no species hurt, but none of the dinosaurs understood the thing
            following them. Was it dangerous and bad for them? Or simply a new part of their life?
          </S.Description>
          <S.Description>
            Time went by and after decades the dinosaurs got used to the observing shadow around
            them. They stopped fearing it. It was more of less just like the tides that came and
            went by again. Like a fire that spreads caused by a thunderstorm. Nature was brutal but
            it was inevitable. But no one could have prepared them for the final time.
          </S.Description>
          <S.Description>It was coming.</S.Description>
          <S.Description>It was going to destroy.</S.Description>
          <S.Description>And It was not going to spare any lives.</S.Description>
          <S.Description>
            The aquatics noticed it first. The salt water was changing colors, fish began to die and
            all they could do was flee from their natural habitats to survive. As the land seemed to
            shrink by unknown forces it was the shadow, the entity that began to show up once more
            and the dead fish that had gathered on the surface sprung back to life as the waters
            color changed back to normal. Was it helping the dinosaurs? Why was it helping them? Not
            a single species understood but they hoped whatever was causing this downfall would
            stop.
          </S.Description>
          <S.Description>
            And that’s when the unthinkable happened. The first to hear and see the dark entity
            first was no other but the ruler, the king of the Dinoverse. An old and strong
            Tyrannosaur. It was him that the entity decided to reveal itself to. At first the
            Tyrannosaurs throat began to rumble lowly with a throaty roar that would make your very
            bones shiver and let the air around you vibrate. The entity felt hot air breathed its
            way before all came to a stop.
          </S.Description>
          <S.Description isBold>-They are coming.-</S.Description>
          <S.Description>
            Just as the entity began to shift shape and mold itself into what could be described as
            human looking being, the Tyrannosaur could...hear it? Like a soft high pitched melody
            inside of its head. Was it speaking to him? The Tyrannosaurs pupils focused on the not
            human silhouette looking entity and waited for another noise in its head.
          </S.Description>
          <S.Description isBold>-We need your help.-</S.Description>
          <S.Description>
            Help? Who needed help? Who is ‘we’? Why did they need help? From him? How could he help?
            So many questions began to form in the head of the Tyrannosaur as he kept watching
            closely for the entity's next move.
          </S.Description>
          <S.Description isBold>-Can no longer protect you.-</S.Description>
          <S.Description>
            Protect from what? Were they all in danger? Were the dinosaurs in danger?
          </S.Description>
          <S.Description isBold>-It is time.-</S.Description>
          <S.Description>
            Time for what? But as the large carnivore kept staring, a light began to shine out of
            the dark entity and before him stood a now bright shining, yellow light.
          </S.Description>
          <S.Description>
            -We are many. You know us as shadows. Observing and watching over you. Call us Guardian
            as we are who will protect you. We are the universe. We are one with you Tyrannosaur.-
          </S.Description>
          <S.Description isBold>Gods? Were they gods? Did they create this world?</S.Description>
          <S.Description>
            -Yes we did. And now this very world is in danger, old friend. We need your help to
            fight this new enemy. Fight now or die. The Metaverse is on the brink of collapsing.-
          </S.Description>
          <S.Description isBold>
            I will help. I want to help. But how? How can the dinosaurs help you?
          </S.Description>
          <S.Description>
            As the Tyrannosaur kept asking these questions time stood still and before he even
            blinked once, a million colors began to suck it deeper into an endless hole. Not even a
            second later with the feeling of being ripped apart inside and put back together, he was
            not standing in front of a huge laboratory.
          </S.Description>
          <S.Description isBold>What is this place?</S.Description>
          <S.Description>
            -A facility to enhance your body to prepare for the fight. We will enhance you, make you
            stronger and deadlier than ever before. Turn you into half of machines. Your offspring
            will be mix-bred and genetically enhanced to create a new, even stronger generation to
            keep advancing. This will be your future old friend. Do you accept?-
          </S.Description>
          <S.Description>
            If not helping means the end of my world, I happily accept. Tear me down, cause me pain
            as long as I can be useful to you. As long as it means to protect my world.
          </S.Description>
          <S.Description isBold>I accept.</S.Description>
          <S.Description>
            The bright entity began to manifest and multiply into many guardians to sweep the land
            and gather the creatures to fight for their causes. The king, the old Tyrannosaur, was
            leading the attack and so the rest would follow eventually.
          </S.Description>
          <S.Description>
            But who are the Guardians? Well, that would be you dear user. All of you are the
            Guardians. Are you willing to fight and protect the lands of the Dinoverse?
          </S.Description>
          <S.Description>
            Upgrade, enhance and change your species into power machines and strong alternative
            versions to fight the unknown. Are you strong enough to survive?
          </S.Description>
          <S.Description>Let the battle begin.</S.Description>
        </S.WrapDescription>
        <S.ItalicHeading>Let’s start Project Dinoverse!</S.ItalicHeading>
      </S.Wrapper>
    </>
  );
}

export default Story;
