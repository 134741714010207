import React from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css';
import Routes from './routes';
import GlobalStyle from './styles/globalStyle';
import theme from './styles/theme';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
        <GlobalStyle />
        <ToastContainer position="top-center" hideProgressBar autoClose={1000} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
