import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Button from '../../components/commons/Button';
import * as S from './styled';
import mockData from './mockup-data';

function Collection() {
  const settingsTablet = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const mockCollection = [
    {
      thumbnail: mockData.Tyranno,
      name: 'T-REX',
      endpoint: '/collection-detail',
    },
    {
      thumbnail: mockData.Raptor,
      name: 'Raptor',
      endpoint: '/collection-raptor',
    },
    {
      thumbnail: mockData.Triceratops,
      name: 'Triceratops',
    },
    {
      thumbnail: mockData.Pterodactyl1,
      name: 'Pterodactyl',
    },
    {
      thumbnail: mockData.Pterodactyl2,
      name: 'Mosasaurus',
    },
    {
      thumbnail: mockData.Baby,
      name: 'Baby Dinos',
    },
    {
      thumbnail: mockData.Egg,
      name: 'Eggs',
    },
  ];

  const handleScroll = (e) => {
    const el = e.target;
    if (el.scrollHeight - el.scrollTop === el.clientHeight) {
      window.fullpage_api?.moveSectionDown();
    }
    if (el.scrollTop === 0) {
      window.fullpage_api?.moveSectionUp();
    }
  };

  return (
    <>
      {window.innerWidth > 768 ? (
        <S.Wrapper>
          <S.WrapLogo onClick={() => window.fullpage_api?.moveTo(1)} style={{ cursor: 'pointer' }}>
            <img src={mockData.logo} alt="" />
          </S.WrapLogo>
          <S.Heading>FACT SHEETS + RARITY CHARTS</S.Heading>
          <S.Line></S.Line>
          <S.WrapCollection id="collection" onScroll={handleScroll}>
            {mockCollection?.map((item) => (
              <S.WrapCard backgroundImage={mockData.CardFrame}>
                <img src={item.thumbnail} alt="" />
                <Link to={`${item?.endpoint ? item.endpoint : '/lite'}`} className="btn-preview">
                  {item.name}
                </Link>
              </S.WrapCard>
            ))}
          </S.WrapCollection>
        </S.Wrapper>
      ) : (
        <S.WrapperMobile>
          <S.WrapLogo>
            <img src={mockData.logo} alt="" />
          </S.WrapLogo>
          <S.Heading style={{ margin: '0 auto 24px' }}>FACT SHEETS + RARITY CHARTS</S.Heading>
          <S.Line style={{ margin: '0 auto 83px' }}></S.Line>
          {window.innerWidth > 500 ? (
            <Slider {...settingsTablet}>
              {mockCollection?.map((item) => (
                <S.WrapCard backgroundImage={mockData.CardFrame}>
                  <img src={item.thumbnail} alt="" />
                  <Link to={`${item?.endpoint ? item.endpoint : '/lite'}`} className="btn-preview">
                    {item.name}
                  </Link>
                </S.WrapCard>
              ))}
            </Slider>
          ) : (
            <Slider {...settingsMobile}>
              {mockCollection?.map((item) => (
                <S.WrapCard backgroundImage={mockData.CardFrame}>
                  <img src={item.thumbnail} alt="" />
                  <Link to={`${item?.endpoint ? item.endpoint : '/lite'}`} className="btn-preview">
                    {item.name}
                  </Link>
                </S.WrapCard>
              ))}
            </Slider>
          )}
        </S.WrapperMobile>
      )}
    </>
  );
}

export default Collection;
