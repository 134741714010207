import styled, { css } from 'styled-components';
import media from '../../styles/breakpoints';

export const WrapContent = styled.div`
  padding: 50px;
  width: 100%;
  height: 100vh;
  text-align: center;

  .background-cloud {
    position: absolute;
    top: 0;
    right: 0;
  }

  ${({ $background }) =>
    $background &&
    css`
      background: ${({ $background }) => $background};
    `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${media.lTablet`
    top: 23px;
    left: 16px;
  `}
`;

export const WrapHeader = styled.div`
  .header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    margin-top: 50px;

    background: linear-gradient(180deg, #516462 0%, #23312f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .sub-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #222f2d;
    padding-top: 8px;
  }

  .line {
    width: 79px;
    border: 1px solid #4f9f14;
    margin: 0 auto;
    margin-top: 24px;
  }

  ${media.hdDesktop`
  .header {
      font-size: 30px;
    }
    `}

  ${media.smallDesktop`
    .header {
      font-size: 30px;
    }
      `}



  ${media.phone` 

    .header{
      font-size: 34px;
    }

    .sub-header{
      font-size: 12px;
    }
  `}

  ${media.sPhone` 

  .header{
    font-size: 30px;
  }

  .sub-header{
    font-size: 11px;
  }
`}

${media.glxFold` 

.header{
  font-size: 20px;
}

.sub-header{
  font-size: 8px;
}
`}
`;

export const WrapRoadMapMobile = styled.div`
  display: none;

  .slick-dots li.slick-active button:before {
    color: #15a06e;
  }

  ${media.tablet`
  display: none;

  `}

  ${media.phone`
  display:block;

    .content-roadmap{
      margin-left: -15px;
      flex-basis: 80%;
    }
    
`}

${media.glxFold` 
display:block;

.content-roadmap{
  margin-left: -38px;
  flex-basis: 100%;
}

`}
`;

export const WrapRoadMap = styled.div`
  min-width: 100%;
  margin-top: 100px;
  padding: 0 200px;

  ${media.desktop`
    padding: 0 ;
    margin-top: 70px;
  `}

  ${media.hdDesktop`
  padding: 0 ;
  margin-top: 70px;
  `}

  ${media.smallDesktop`
  padding: 0 ;
  margin-top: 70px;
  `}

  ${media.phone`
    display:none
  `}
`;

export const WrapTimeLine = styled.div`
  margin: 3.8rem 0px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  margin: 0 auto;
  margin-top: 65px;

  &:after {
    content: '';
    min-width: 100%;
    height: 1px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background: linear-gradient(53.87deg, #319fff 13.76%, #5cae18 86.54%);
    margin: auto;
  }
  ${media.phone`
  width: 88%;
`}
`;

export const Stage = styled.div`
  display: flex;
  position: relative;
  flex-basis: 20%;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 10;

  & > p {
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: -48px;
    background: #deebff;
    width: 100px;
    height: 29px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.22em;
    text-transform: uppercase;
  }

  & > p:after {
    content: ' ';
    position: absolute;
    right: 89px;
    bottom: -10px;
    border-top: 10px solid #deebff;
    border-right: 11px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: none;
  }

  .stage-opacity {
    opacity: 0.4;
  }

  ${media.largeDesktop`
      & > p{
        width: 175px;
        height: 30px;
        font-size: 20px;
        line-height: 30px;
      }

      & > p:after{
        right: 164px;
        bottom: -9px;
      }
      `}

  ${media.fullHDDesktop`
      & > p{
        width: 100px;
        height: 29px;
        font-size: 14px;
      }
  
      & > p:after{
        right: 89px;
      }
      `}

  ${media.hdDesktop`
    & > p{
      width: 100px;
      height: 29px;
      font-size: 14px;
    }

    & > p:after{
      right: 89px;
    }
    `}

    ${media.smallDesktop`
      & > p{
        width: 75px;
        height: 23px;
        font-size: 12px;
      }

      & > p:after{
        right: 64px;
      }
    `}

 

${media.glxFold` 
& > p {
  font-size: 7px;
  width: 44px;
  height: 19px;
}

& > p:after{
  left: 0;
}


`}
`;

export const Circle = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: linear-gradient(53.87deg, #319fff 13.76%, #5cae18 86.54%);
  position: relative;
  transition: all 0.6s ease 0s;
  cursor: pointer;
`;

export const ContentStage = styled.div`
  width: 100%;
  display: flex;
  margin-left: 110px;

  ${media.largeDesktop`
      margin-left: 200px;
    `}

  ${media.fullHDDesktop`
      margin-left: 110px;
    `}

  ${media.hdDesktop`
    margin-left: 95px;
  `}

  ${media.smallDesktop`
  margin-left: 90px;
  `}

  ${media.lTablet`
  margin-left: 71px;
  `}

  ${media.tablet`
  margin-left: 62px;
  `}
`;

export const Content = styled.div`
  flex-direction: column;
  flex-basis: 20%;
  display: flex;
  margin-top: 30px;
  padding: 0 30px;

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #2a3635;
    text-align: left;
  }

  .content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #2a3635;
    margin-top: 6px;
    text-align: left;
    margin-bottom: 11px;
    text-align: left;
    display: inline-block;
    margin-left: 10px;
  }

  .opacity {
    opacity: 0.4;
  }

  .content > span {
    display: inline-block;
    margin-left: 10px;
  }

  ${media.largeDesktop`
    padding: 0 ;
    
    .title{
      font-size:25px
    }

    .content{
      font-size:23px;
      line-height: 28px;
    }
   `}

  ${media.fullHDDesktop`
    padding: 0 30px;
    .title{
      font-size: 14px;
    }

    .content{
      font-size: 14px;
      line-height:18px;
    }
    `}

    ${media.hdDesktop`
    padding: 0 30px;
    .title{
      font-size: 12px;
    }

    .content{
      font-size: 11px;
      line-height:13px;
    }
    `}

    ${media.smallDesktop`
     padding: 0 ;

     .content{
       font-size:10px;
     }
    `}
`;

export const ImageBottom = styled.div`
  & > img {
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  ${media.largeDesktop`
      & > img{
        width: auto;
        height: 450px;
      }
      `}

  ${media.fullHDDesktop`
    & > img{
      width: auto;
      height: 300px;
    }
    `}

    ${media.hdDesktop`
    & > img{
      width: auto;
      height: 180px;
    }
    `}

    ${media.smallDesktop`
      & > img{
        width: auto;
        height: 120px;
      }
      `}

      ${media.glxFoldBig`
      & > img{
        width: auto;
        height: 85px;
      }
      `}
`;
