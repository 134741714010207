import * as S from './styled';
import logo from '../../assets/icons/logo.svg';
import backgroundCloud from '../../assets/image/png/background-cloud.png';

function AboutUs() {
  return (
    <S.Wrapper
      style={{
        backgroundImage: `url(${backgroundCloud}), linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
      }}
    >
      <S.WrapLogo>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.Heading>About Us</S.Heading>
      <S.SubHeading>See what we have planned for the future</S.SubHeading>
      <S.Line></S.Line>
      <S.Description>We are a global team based in Australia, Europe and US.</S.Description>
      <S.Description>
        Our team comprises of 3D graphic specialists, concept artists, blockchain and start-up
        veterans.
      </S.Description>
    </S.Wrapper>
  );
}

export default AboutUs;
