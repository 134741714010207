import React from 'react';
import styled from 'styled-components';
import { Html, useProgress } from '@react-three/drei';
import { Spin, Space } from 'antd';

export const Wrapper = styled.div`
  width: 100%;

  .ant-spin-dot-item {
    background-color: #15a06e;
  }
`;

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <Wrapper>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </Wrapper>
    </Html>
  );
}

export default Loader;
