import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { ROUTES } from '../../routes/constants';
import Button from '../../components/commons/Button';

import logo from '../../assets/icons/logo.svg';
import buffy from './images/buffy.png';

import * as S from './styled';

function SaleCommingSoon() {
  const history = useHistory();

  return (
    <S.Wrapper
      style={{
        background: 'linear-gradient(180deg, #95B9EF 0%, rgba(192, 216, 253, 0) 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <S.WrapLogo>
        <img src={logo} alt="" />
      </S.WrapLogo>
      <S.WrapContent>
        <S.Top>
          <div className="title">RAPTORS SALE IS HERE!</div>
          <ReactPlayer
            className="video-youtube"
            playing
            muted
            loop
            controls
            width="1000px"
            height="563px"
            url="https://www.youtube.com/watch?v=iOmmlatrmLk"
          />

          <div className="sub-title">
            Our public sale for raptor will go live at March 06 UTC 00:00 <br /> Visit our Buffy Bot
            page below to proceed to our Buffy Bot sale page
          </div>

          <div className="button">
            <a href="https://dinoverse.buffybot.io" target="_blank" rel="noreferrer">
              <Button
                label="BUFFY BOT SALE PAGE"
                className="button-back"
                style={{ background: 'linear-gradient(90deg, #F9931A 0%, #D35610 99.99%)' }}
                hasIcon
                openIcon
              />
            </a>

            <Button
              label="BACK TO HOMEPAGE"
              onClick={() => history.push(ROUTES.HOME)}
              className="button-back"
            />
          </div>
        </S.Top>
        <S.Bottom>
          <div className="buffy">
            <img src={buffy} alt="" />
          </div>
          <div className="policy">
            Policy ID: 926075ba72037da019e28565bd379662202132723dc10bd13ffc6850
          </div>
          <div className="copyright">Copyright © 2022 Dinoverse. All rights reserved</div>
        </S.Bottom>
      </S.WrapContent>
    </S.Wrapper>
  );
}

export default SaleCommingSoon;
