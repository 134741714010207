import styled, { css } from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  display: flex;

  .background-cloud {
    position: absolute;
    top: 0;
    right: 0;
  }

  ${media.lTablet`
    flex-direction: column;
  `}

  ${({ $background }) =>
    $background &&
    css`
      background: ${({ $background }) => $background};
    `}
`;

export const WrapModel = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 50%;
  cursor: grab;

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  ${media.hdDesktop`
    flex-basis: 60%;
  `}

  ${media.lTablet`
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  `}

  ${media.glxFoldBig`
    .mobile {
      display: none;
    }
  `}

  ${media.phone`
    .mobile {
      display: block;
    }
  `}
`;

export const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 50px 0 0 151px;

  ${media.desktop`
    padding: 50px 0 0 70px;
    flex-basis: 50%;
  `}

  ${media.hdDesktop`
    padding: 50px 0 0 50px;
    flex-basis: 40%;
  `}

  ${media.lTablet`
    padding: 50px 15px 0px 15px;
  `}
`;

export const Announcement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17.5px;
  text-align: left;
  margin-top: 40px;

  .announcement {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    color: #707d7c;

    img {
      margin-right: 8px;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2a3635;
    font-family: Montserrat;

    img {
      margin-right: 8px;
    }

    span {
      font-weight: 700px;
      color: #15a06e;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${media.hdDesktop`
    margin-top: 30px;
  `}

  ${media.xsPhone`
    margin-top: 20px;
  `}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;

  .big-logo {
    ${media.lTablet`
      display: none;
    `}
  }

  .small-logo {
    position: absolute;
    display: none;
    top: 23px;
    left: 16px;

    ${media.lTablet`
      display: block;
    `}
  }

  .svg {
    font: bold 90px Montserrat;
    width: 100%;
    height: 72px;
  }

  .text-header {
    padding-right: 350px;
    font-weight: 900;
    font-size: 72px;
    line-height: 70px;
    text-align: left;
    font-family: Montserrat;
    color: #425452;
    text-transform: uppercase;
    margin-top: 70px;

    .pangea {
      background: -webkit-linear-gradient(180deg, #f9931a 61.64%, #d35610 89.66%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .button {
    margin-top: 30px;
  }

  ${media.fullHDDesktop`
    .text-header {
      padding-right: 50px;
      font-size: 72px;
    }

    .button {
      margin-top: 50px;
    }
  `}

  ${media.desktop`
    .text-header {
      padding-right: 0px;
      margin-top: 50px;
      font-size: 68px;
    }

    .button {
      margin-top: 20px;
    }
  `}

  ${media.mdDesktop`
    .text-header {
      font-size: 60px;
    }
  `}

  ${media.hdDesktop`
    .text-header {
      font-size: 40px;
      line-height: 48px;
      margin-top: 30px;
    }

    .button {
      margin-top: 20px;
    }
  `}

  ${media.smallDesktop`
    .text-header {
      font-size: 35px;
      line-height: 45px;
    }

    .button {
      margin-top: 20px;
    }
  `}

  ${media.lTablet`
    align-items: center;
    .text-header {
      text-align: center;
      font-size: 44px;
      line-height: 58px;
    }
  `}

  ${media.tablet`
    .text-header {
      font-size: 40px;
    }
  `}

  ${media.glxFoldBig`
    .text-header {
      font-size: 38px;
      line-height: 36px;
      margin-top: 20px;
    }
  `}

  ${media.phone`
    .text-header {
      font-size: 36px;
      line-height: 38px;
    }
  `}

  ${media.xsPhone`
    .text-header {
      font-size: 32px;
      line-height: 36px;
      margin-top: 10px;
    }

    .button {
      margin-top: 10px;
    }
  `}

  ${media.glxFold`
    .text-header {
      font-size: 24px;
      line-height: 26px;
    }
  `}
`;

export const FollowUs = styled.div`
  margin-top: 60px;
  font-weight: bold;
  font-size: 18px;
  line-height: 11px;
  color: #707d7c;
  font-family: Montserrat;

  ${media.fullHDDesktop`
    margin-top: 50px;
  `}

  ${media.desktop`
    margin-top: 40px;
  `}

  ${media.hdDesktop`
    margin-top: 30px;
  `}

  ${media.xsPhone`
    margin-top: 30px;
  `}
`;

export const IconFollowUs = styled.div`
  margin-top: 20px;

  .icon-twitter {
    width: auto;
    height: 20px;
    padding: 0 20px 0 0;
    border-right: 1px solid #a0afae;
    line-height: 1px;
  }

  .icon-discord {
    width: auto;
    height: 20px;
    padding: 0 0 0 20px;
  }
`;

export const Model = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 30%;

  .map-left {
    opacity: 0.8;
    position: absolute;
    width: 15%;
    left: -100px;
    animation: map-left 5s linear infinite;
    @keyframes map-left {
      0%,
      100% {
        top: 130px;
      }
      50% {
        top: 200px;
      }
    }
  }

  .map-right {
    position: absolute;
    left: 30%;
    width: 20%;
    animation: map-right 6s linear infinite;
    @keyframes map-right {
      0%,
      100% {
        top: 170px;
      }
      50% {
        top: 230px;
      }
    }
  }

  .animation-cloud {
    position: absolute;
    left: 0px;
    animation: run 25s linear infinite;

    @keyframes run {
      0% {
        left: 0;
      }
      50% {
        left: calc(100% - 100px);
      }
      100% {
        left: 0;
      }
    }
  }

  ${media.fullHDDesktop`
    .map-left {
      left: -50px;
      animation: map-left 5s linear infinite;
      @keyframes map-left {
        0%,
        100% {
          top: 170px;
        }
        50% {
          top: 210px;
        }
      }
    }

    .map-right {
      left: 400px;
      animation: map-right 6s linear infinite;
      @keyframes map-right {
        0%,
        100% {
          top: 50px;
        }
        50% {
          top: 160px;
        }
      }
    }
  `}

  ${media.hdDesktop`
    .map-left {
      animation: map-left 5s linear infinite;
      @keyframes map-left {
        0%,
        100% {
          top: 130px;
        }
        50% {
          top: 170px;
        }
      }
    }

    .map-right {
      animation: map-right 6s linear infinite;
      @keyframes map-right {
        0%,
        100% {
          top: 20px;
        }
        50% {
          top: 120px;
        }
      }
    }
  `}

  ${media.smallDesktop`
    .map-left {
      animation: map-left 8s linear infinite;
      @keyframes map-left {
        0%,
        100% {
          top: 150px;
        }
        50% {
          top: 190px;
        }
      }
    }

    .map-right {
      left: 190px;
      width: 30%;
      animation: map-right 8s linear infinite;
      @keyframes map-right {
        0%,
        100% {
          top: 0px;
        }
        50% {
          top: 140px;
        }
      }
    `}

    ${media.lTablet`
      .map-left {
        display: none;
      }

      .map-right {
        display: none;
    `}
`;
