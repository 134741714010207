import React, { memo } from 'react';
import PropTypes from 'prop-types';

import icon from '../../../assets/image/svg/TRexFoot.svg';
import open_icon from '../../../assets/icons/icon.svg';

import * as S from './styled';

Button.propTypes = {
  label: PropTypes.string,
  hasIcon: PropTypes.bool,
  openIcon: PropTypes.bool,
};

function Button({ label, hasIcon, openIcon, ...rest }) {
  return (
    <S.Button {...rest}>
      {label} {hasIcon && <img src={openIcon ? open_icon : icon} alt="" />}
    </S.Button>
  );
}

export default Button;
