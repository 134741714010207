import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import ModelPangea from './ModelPangea';
import Loader from '../Loader';

export default function SceneMobile({ ...rest }) {
  return (
    <Canvas {...rest}>
      <Suspense fallback={<Loader />}>
        <ModelPangea
          position={[0, -6, 0.8]}
          scale={1.5}
          rotation={[(5 * Math.PI) / 180, (66 * Math.PI) / 180, 0]}
        />
      </Suspense>
    </Canvas>
  );
}
