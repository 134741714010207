export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  ROADMAP: '/road-map',
  COLLECTION_DETAIL: '/collection-detail',
  ABOUT_US: '/about-us',
  STORY: '/story',
  LITE: '/lite',
  COLLECTION_RAPTOR: '/collection-raptor',
  SALE: '/sale',
  PANGEA: '/pangea',
  COMING_SOON: '/coming-soon',
  SALE_COMING_SOON: '/sale-coming-soon',
  MEDIA: '/social-media',
};

export const ROUTES_ADMIN = {};
