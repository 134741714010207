/* IMAGE */

import logo from '../../assets/image/png/logo.png';
import map1 from '../../assets/image/png/map1.png';
import map2 from '../../assets/image/png/map2.png';
import earth from '../../assets/image/png/earth.png';
import trexGif from '../../assets/image/gif/TRex.gif';
import cloud from '../../assets/image/png/cloud.png';
import backgroundCloud from '../../assets/image/png/background-cloud.png';

/* ICON */

import iconTRex from '../../assets/image/svg/TRexFoot.svg';
import twitter from '../../assets/image/svg/twitter.svg';
import discord from '../../assets/image/svg/discord.svg';

export default {
  logo,
  iconTRex,
  twitter,
  discord,
  map1,
  map2,
  earth,
  trexGif,
  cloud,
  backgroundCloud,
};

export const listRoadMap = [
  {
    stage: 'Stage 1',
    title: '2021',
    content: ['- Dinoverse Launch', '- Dinoverse Memberships', '- T-Rex sale'],
  },
  {
    stage: 'Stage 2',
    title: 'Q1 2022',
    content: ['- Raptors', '- Pangea Land Parcel Sale', '- Pangea structures'],
  },
  {
    stage: 'Stage 3',
    title: 'Q2-Q3 2022',
    content: [
      '- Triceratops, Pterodactyl, Stegosaurus, Brachiosaurus..',
      '- Eggs and Baby Dinos',
      '- Advanced Attributes',
    ],
  },
  {
    stage: 'Stage 4',
    title: 'Q4 2022-2023',
    content: [
      '- Weapons & Accessories',
      '- Battle Arena',
      '- Battle Tokens',
      '- Dino Reserves',
      '- Other Mythical Creatures',
    ],
  },
];
