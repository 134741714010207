import styled, { css } from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  font-family: Montserrat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  z-index: 20;
  &::-webkit-scrollbar {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    &:hover {
      background-color: lightgray;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    &:hover {
      background: transparent;
    }
  }
  ${media.phone`
    justify-content: flex-start;
    padding: 0 20px;
  `}
`;

export const WrapLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;

  ${media.lTablet`
    left: 16px;
    top: 23px;
  `}
`;

/*
export const Heading = styled.h1`
  margin-top: 135px;
  margin-bottom: 40px;
  max-width: 900px;
  font-weight: 900;
  font-style: normal;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to left, #149f6d, #06a79d);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  ${media.smallDesktop`
    font-size: 40px;
  `}
  ${media.phone`
    font-size: 33px;
  `}
  ${media.smallPhone`
    font-size: 22px;
  `}
  z-index: 10;
`;
*/

export const Heading = styled.h1`
  margin-top: 135px;
  margin-bottom: 40px;
  max-width: 900px;
  font-weight: 900;
  font-style: normal;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to left, #149f6d, #06a79d);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  ${media.smallDesktop`
    font-size: 40px;
  `}
  ${media.phone`
    font-size: 33px;
  `}
  z-index: 10;
`;

export const SubHeading = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #2a3635;
  margin-bottom: 24px;
  ${media.smallDesktop`
    font-size: 14px;
  `}
  ${media.phone`
    font-size: 13px;
  `}
  z-index: 10;
`;

/*
export const Title = styled.h3`
  font-size: 32px;
  line-height: 58px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #516462, #23312f);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 22px;
  ${media.smallDesktop`
    font-size: 27px;
  `}
  ${media.smallPhone`
    font-size: 20px;
  `}
  z-index: 10;
`;
*/

export const Title = styled.h3`
  font-size: 32px;
  line-height: 58px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #516462, #23312f);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 22px;
  ${media.smallDesktop`
    font-size: 27px;
  `}
  z-index: 10;
`;

export const WrapDescription = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

/*
export const Description = styled.p`
  max-width: 770px;
  font-size: 16px;
  line-height: 30px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  text-align: center;
  color: #2a3635;
  margin-bottom: 30px;
  ${media.smallDesktop`
    font-size: 16px;
  `}
  ${media.smallDesktop`
    font-size: 14px;
  `}
  ${media.smallPhone`
    font-size: 12px;
  `}
  z-index: 10;
`;
*/

export const Description = styled.p`
  max-width: 770px;
  font-size: 16px;
  line-height: 30px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  text-align: center;
  color: #2a3635;
  margin-bottom: 30px;
  ${media.smallDesktop`
    font-size: 16px;
  `}
  ${media.smallDesktop`
    font-size: 14px;
  `}
  z-index: 10;
`;

/*
export const ItalicHeading = styled.h2`
  margin-top: 80px;
  margin-bottom: 200px;
  font-family: Montserrat;
  font-size: 48px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 58px;
  text-align: center;
  background-image: linear-gradient(to left, #149f6d, #06a79d);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  ${media.smallDesktop`
    font-size: 40px;
  `}
  ${media.phone`
    font-size: 33px;
  `}
  ${media.smallPhone`
    font-size: 22px;
  `}
  z-index: 10;
`;
*/

export const ItalicHeading = styled.h2`
  margin-top: 80px;
  margin-bottom: 200px;
  font-family: Montserrat;
  font-size: 48px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 58px;
  text-align: center;
  background-image: linear-gradient(to left, #149f6d, #06a79d);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  ${media.smallDesktop`
    font-size: 40px;
  `}
  ${media.phone`
    font-size: 33px;
  `}
  z-index: 10;
`;

export const RevealArea = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 10;
  ${media.tablet`
    display: none;
  `}
`;

export const RevealBackground = styled.div`
  z-index: 5;
  position: absolute;
  top: calc(50% - 10rem);
  left: calc(50% - 10rem);
  width: 20rem;
  height: 20rem;
  background: url(${({ backgroundImage }) => backgroundImage}) 50% 50% no-repeat fixed;
  background-size: cover;
  border-radius: 50%;
`;
