import styled from 'styled-components';
import media from '../../styles/breakpoints';

export const Wrapper = styled.div`
  font-family: Montserrat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #191b1f;
  position: relative;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    rgba(33, 114, 229, 0.1) 0,
    rgba(33, 36, 41, 0) 100%
  );
  z-index: 900;
`;
export const TopNavToggle = styled.span`
  display: none;
  margin-right: 15px;
  ${media.lTablet`
    display: inline;
  `}
`;
export const TopNav = styled.div`
  padding: 12px 24px;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 10;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadde1;
  background-color: #191b1f;
  box-shadow: 0 1px 10px 0 transparent;
  .topnav-logo {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    line-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    :hover {
      opacity: 1;
      color: #fff;
    }
    ${media.tablet`
      max-width: 60vw;
    `}
  }
`;
export const MainWrapper = styled.div`
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background: transparent;
    ${media.tablet`
      display: none;
    `}
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    &:hover {
      background-color: lightgray;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    &:hover {
      background: transparent;
    }
  }
`;
export const SideBar = styled.div`
  width: 280px;
  min-width: 280px;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dadde1;
  position: sticky;
  top: 0;
  background-color: #191b1f;
  letter-spacing: 0.04rem;
  ul {
    width: 100%;
    li {
      padding: 3px 8px;
      margin: 4px 0;
      cursor: pointer;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #dadde1;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #fff;
      }
      a {
        display: inline-block;
        padding: 3px 8px;
        width: 100%;
        color: #dadde1;
      }
      &:hover {
        background: hsla(0, 0%, 100%, 0.05);
        color: #fff;
        a {
          opacity: 1;
          color: #fff;
        }
      }
    }
  }
  ${media.lTablet`
    position: fixed;
    left: ${({display}) => display ? '0' : '-75vh'};
    top: 0;
    width: 75vw;
    height: 100vh;
    transition: all .5s;
    z-index: 999;
  `}
`;

export const CloseSideBarBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
`;
export const ContentWrapper = styled.div`
  width: 100%;
  padding: 32px 170px;
  display: flex;
  justify-content: center;
  gap: 0 80px;
  ${media.desktop`
    padding: 32px 15px;
    gap: 0 50px;
  `}
  ${media.smallDesktop`
    gap: 0 20px;
  `}
`;
export const Content = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  color: #f5f6f7;
  ${media.desktop`
    max-width: 700px;
  `}
  ${media.hdDesktop`
    max-width: 600px;
  `}
  ${media.smallDesktop`
    max-width: 500px;
  `}
  ${media.tablet`
    max-width: 100%;
  `}
  .content-heading {
    margin-bottom: 25px;
    margin-top: 75px;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    color: #f5f6f7;
    letter-spacing: 0.08rem;
    ${media.smallDesktop`
      font-size: 30px;
    `}
  }
  .content-label {
    margin: 40px 0 20px;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    color: #f5f6f7;
    letter-spacing: 0.06rem;
    &-lv2 {
      font-size: 22px;
      padding-bottom: 16px;
      opacity: 0.9;
    }
    ${media.smallDesktop`
      font-size: 26px;
    `}
  }
  .content-text {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01rem;
    &__link {
      color: #fff;
      word-break: break-all;
      :hover {
        text-decoration: underline;
      }
    }
    ${media.smallDesktop`
      font-size: 12px;
    `}
  }
  .content-list {
    margin-bottom: 20px;
    padding-left: 32px;
    line-height: 24px;
    letter-spacing: 0.01rem;
    font-size: 14px;
    &--decimal {
      list-style-type: decimal;
    }
    &--disc {
      list-style-type: disc;
    }
    &--alpha {
      list-style-type: lower-alpha;
    }
    li p {
      strong {
        font-weight: bold;
      }
      margin: 6px 16px;
    }
  }
`;
export const Search = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 280px;
  input {
    display: inline-block;
    border: 1px solid #fff;
    padding: 8px 10px;
    background: transparent;
    color: #fff;
    font-size: 14px;
    // ::placeholder {
    //   color: #fff;
    // }
  }
  ${media.desktop`
    width: 200px;
  `}
  ${media.smallDesktop`
    width: 170px;
  `}
  ${media.tablet`
    display: none;
  `}
`;

export const SearchBox = styled.div`
  width: 280px;
  position: fixed;
  top: 100px;
  h3 {
    color: #fff;
    margin-bottom: 8px;
  }
  ${media.desktop`
    width: 200px;
    input {width: 200px;}
  `}
  ${media.smallDesktop`
    width: 170px;
    input {
      width: 170px;
      font-size: 12px;
      padding: 5px 8px;
    }
  `}
  
`;
