import { css } from 'styled-components';

const utility = css`
  :root {
    --bs-primary: #d72323;
  }
  .container {
    padding: 0;
    @media (max-width: 576px) {
      width: 100%;
    }
    @media (min-width: 576px) {
      width: 540px;
    }
    @media (min-width: 768px) {
      width: 720px;
    }
    @media (min-width: 992px) {
      width: 960px;
    }
    @media (min-width: 1200px) {
      width: 1140px;
    }
    @media (min-width: 1400px) {
      width: 1170px;
    }
  }
  .cursorPointer {
    cursor: pointer;
  }

  /* DatePicker */
  .ant-picker {
    border-radius: ${({ theme }) => theme.borderRadius.base};
    padding: 12px;
    box-shadow: none;
    width: 100%;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-picker-panel-container {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    padding: 16px;
  }
  .ant-picker-header-view button:hover {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-picker-content th,
  .ant-picker-content td {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  .ant-picker-date-panel .ant-picker-content th {
    color: ${({ theme }) => theme.colors.gray3};
  }
  .ant-picker-cell {
    padding: 8px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryButtonColor};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: 0px 4px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
  }
  .ant-picker-footer {
    /* display: none; */
  }
  .ant-picker.ant-picker-disabled {
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }
  .ant-picker-today-btn {
    color: ${({ theme }) => theme.colors.primaryButtonColor};

    &:hover {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  /* TimePicker */
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: ${({ theme }) => theme.colors.primaryButtonColor};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-picker-now > a {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-btn-primary {
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
    background: ${({ theme }) => theme.colors.primaryButtonColor};

    &:hover,
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.red} !important;
      background: ${({ theme }) => theme.colors.red} !important;
      color: #fff !important;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
  }

  .ant-switch-checked {
    background: #d72323;
  }

  .ant-spin-dot-item {
    background-color: #d72323;
  }

  #fp-nav ul li,
  .fp-slidesNav ul li {
    width: 20px;
    margin: 15px 7px;
  }
  #fp-nav ul li a span {
    width: 12px;
    height: 2px;
    background: #425452;
    border-radius: 0;
    margin: 0 0 0 -6px;
  }
  #fp-nav ul li:hover a.active span,
  #fp-nav ul li a.active span,
  .fp-slidesNav ul li:hover a.active span,
  .fp-slidesNav ul li a.active span {
    width: 20px;
    height: 2px;
    background: #15a06e;
    border-radius: 0;
    margin: 0 0 0 -10px;
  }
  #fp-nav ul li:hover a span,
  .fp-slidesNav ul li:hover a span {
    width: 20px;
    height: 2px;
    background: #15a06e;
    border-radius: 0;
    margin: 0 0 0 -10px;
  }
`;
export default utility;
